import { useState } from 'react';
import { Col, Card, Button } from 'react-bootstrap';
import { Plus } from 'react-feather';

import HkTooltip from '../../../components/@hk-tooltip/HkTooltip';

import AddLocationModal from './AddLocationModal';

const AddLocations = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Col md={4}>
        <Card className="card-border border-dashed mnh-250p">
          <Card.Body className="d-flex align-items-center justify-content-center">
            <Button
              variant="outline-light"
              className="btn-block"
              onClick={handleShow}
            >
              <HkTooltip
                title="Add New Address"
                placement="top"
                id="addresstlt1"
              >
                <span className="icon">
                  <span className="feather-icon">
                    <Plus />
                  </span>
                </span>
              </HkTooltip>
            </Button>
          </Card.Body>
        </Card>
      </Col>
      <AddLocationModal show={show} hide={handleClose} />
    </>
  );
};

export default AddLocations;
