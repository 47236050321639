import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Nav,
  Row,
  Tab,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Edit2, Edit3, Minimize, Plus, Trash2 } from 'react-feather';
import HkTooltip from '../../components/@hk-tooltip/HkTooltip';
import HkBadge from '../../components/@hk-badge/@hk-badge';
// Redux
import { connect } from 'react-redux';
//Images
import interest1 from '../../assets/dist/img/interests/interest-1.png';
import interest2 from '../../assets/dist/img/interests/interest-2.png';
import interest3 from '../../assets/dist/img/interests/interest-3.png';
import interest4 from '../../assets/dist/img/interests/interest-4.png';
import interest5 from '../../assets/dist/img/interests/interest-5.png';
import interest6 from '../../assets/dist/img/interests/interest-6.png';
import interest7 from '../../assets/dist/img/interests/interest-7.png';
import interest8 from '../../assets/dist/img/interests/interest-8.png';
import interest9 from '../../assets/dist/img/interests/interest-9.png';
import interest10 from '../../assets/dist/img/interests/interest-10.png';
import interest11 from '../../assets/dist/img/interests/interest-11.png';
import interest12 from '../../assets/dist/img/interests/interest-12.png';

import visaCard from '../../assets/dist/img/card-visa.png';
import masterCard from '../../assets/dist/img/mastercard.png';
import EmployeeList from '../Employees/EmployeeList';
import Stages from '../Stages';

import { api } from '../../api';

import Details from './Details/index';
import Locations from './Locations/index';
import Designations from './Desginations/Designations';

const Body = () => {
  const [company, setCompany] = useState(null);

  useEffect(() => {
    const fetchCompany = async () => {
      const res = await api.get('/admin/company');
      setCompany(res.data.data);
    };
    fetchCompany();
  }, []);

  return (
    <Container>
      <Tab.Container defaultActiveKey="details">
        <div className="hk-pg-header pg-header-wth-tab ">
          <div className="d-flex">
            <div className="flex-1">
              <h1 className="pg-title">Company Settings</h1>
              <p className="p-lg col-lg-8">
                Manage your company account settings and options.
              </p>
            </div>
          </div>
          <Nav variant="tabs" className="nav-line nav-light">
            <Nav.Item>
              <Nav.Link eventKey="details">
                <span className="nav-link-text">Company Details</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="locations">
                <span className="nav-link-text">Company Locations</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="designations">
                <span className="nav-link-text">Designations</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="stages">
                <span className="nav-link-text">Lead Stages</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="hk-pg-body">
          <Tab.Content>
            <Tab.Pane eventKey="stages">
              <Stages />
            </Tab.Pane>
            <Tab.Pane eventKey="locations">
              <Locations company={company} />
            </Tab.Pane>
            <Tab.Pane eventKey="details">
              <Details company={company} />
            </Tab.Pane>
            <Tab.Pane eventKey="designations">
              <Designations />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="designations">
              <Row>
                <Col lg={8}>
                  <div className="title-lg fs-5 justify-content-between mb-5">
                    <span>Saved Cards</span>
                    <Button variant="outline-light">+ Add new card</Button>
                  </div>
                  <ul className="advance-list">
                    <li className="advance-list-item transform-none shadow-none py-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="media align-items-center">
                          <div className="media-head me-5">
                            <img
                              src={visaCard}
                              alt="user"
                              className="img-fluid"
                            />
                          </div>
                          <div className="media-body">
                            <div>
                              <span className="text-dark fw-medium">
                                ****4213
                              </span>
                              <HkBadge
                                bg="primary"
                                soft
                                className="rounded-0 ms-3"
                              >
                                Primary
                              </HkBadge>
                            </div>
                          </div>
                        </div>
                        <div className="d-lg-inline d-none">
                          <span className="fs-7 text-muted me-5 d-xl-inline d-none">
                            Last updated 12/03/2022
                          </span>
                          <Button
                            variant="outline-danger"
                            className="mnw-100p me-2"
                          >
                            Delete
                          </Button>
                          <Button variant="light" className="mnw-100p">
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                    <li className="advance-list-item transform-none shadow-none py-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="media align-items-center">
                          <div className="media-head me-5">
                            <img
                              src={masterCard}
                              alt="user"
                              className="img-fluid"
                            />
                          </div>
                          <div className="media-body">
                            <div>
                              <span className="text-dark fw-medium">
                                ****1214
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-lg-inline d-none">
                          <span className="fs-7 text-muted me-5 d-xl-inline d-none">
                            Last updated 25/04/2022
                          </span>
                          <Button
                            variant="outline-danger"
                            className="mnw-100p me-2"
                          >
                            Delete
                          </Button>
                          <Button variant="light" className="mnw-100p">
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="text-end mt-6">
                    <Button variant="primary" className="btn-rounded">
                      Save changes
                    </Button>
                  </div>
                </Col>
              </Row>
            </Tab.Pane> */}
          </Tab.Content>
        </div>
        {/* /Page Body */}
      </Tab.Container>
    </Container>
  );
};

export default Body;
