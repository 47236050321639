import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { api } from '../../api';

const TodoList = () => {
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    const fetchTodos = async () => {
      try {
        const response = await api.get('/users/todo');
        setTodos(response.data.data);
      } catch (error) {
        console.error('Error fetching todos:', error);
      }
    };

    fetchTodos();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      dataField: 'fullName',
      text: 'Lead Name',
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      formatter: (cell) => formatDate(cell),
    },
    {
      dataField: 'updatedAt',
      text: 'Updated At',
      formatter: (cell) => formatDate(cell),
    },
  ];

  const paginationOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    totalSize: todos.length,
  };

  return (
    <Card
      className="mb-3 mx-auto"
      style={{ borderRadius: '10px', width: '90%' }}
    >
      <Card.Header>
        <Container>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Todo List</h5>
            </Col>
          </Row>
        </Container>
      </Card.Header>
      <Card.Body>
        {todos.length > 0 ? (
          <BootstrapTable
            keyField="id"
            data={todos}
            columns={columns}
            pagination={paginationFactory(paginationOptions)}
            bordered={false}
            wrapperClasses="table-responsive"
            classes="table-striped"
          />
        ) : (
          <p className="text-center">No todos available.</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default TodoList;
