import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Edit3, Save } from 'react-feather';

const DetailItem = ({ label, value, onSave }) => {
  const formatInitialValue = (value, label) => {
    if (label.toLowerCase() === 'incorporationdate' && value) {
      const date = new Date(value);
      return date.toISOString().split('T')[0];
    }
    return value || '';
  };

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(
    formatInitialValue(value, label)
  );
  const [error, setError] = useState('');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (isValidInput(inputValue, label)) {
      setIsEditing(false);
      setError('');
    } else {
      setError(`Please enter a valid url`);
    }
  };

  const getInputType = () => {
    switch (label.toLowerCase()) {
      case 'contactnumber':
        return 'number';
      case 'incorporationdate':
        return 'date';
      case 'website':
      case 'facebook':
      case 'twitter':
      case 'linkedin':
      case 'instagram':
        return 'url';
      default:
        return 'text';
    }
  };

  const isValidInput = (value, label) => {
    if (
      ['website', 'facebook', 'twitter', 'linkedin', 'instagram'].includes(
        label.toLowerCase()
      )
    ) {
      try {
        new URL(value);
        return true;
      } catch (_) {
        return false;
      }
    }
    return true;
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <Form.Control
          type={getInputType()}
          size="lg"
          className="ps-8"
          placeholder={`Enter ${label.toLowerCase()}`}
          value={inputValue}
          readOnly={!isEditing}
          onChange={(e) => setInputValue(e.target.value)}
          isInvalid={!!error}
        />
        <InputGroup.Text
          onClick={isEditing ? handleSaveClick : handleEditClick}
          style={{ cursor: 'pointer' }}
        >
          {isEditing ? <Save size={16} /> : <Edit3 size={16} />}
        </InputGroup.Text>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

export default DetailItem;
