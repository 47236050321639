import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AuthRoutes from './routes/AuthRoutes';
import ScrollToTop from './utils/ScrollToTop';
import AllRoutes from './routes/AllRoutes';
import { useDispatch } from 'react-redux';
import { getUserDetails } from './redux/reducer/UserDetails';
import { useSelector } from 'react-redux';
import LandingPage from './views/LandingPage';
import ErrorRoutes from './routes/ErrorRoutes';

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            {/* <Redirect exact from="/" to="/dashboard" /> */}
            <Route
              exact
              path="/"
              render={(props) => <LandingPage {...props} />}
            />

            {/* Auth */}
            <Route path="/auth" render={(props) => <AuthRoutes {...props} />} />
            {/* Error */}
            <Route
              path="/error"
              render={(props) => <ErrorRoutes {...props} />}
            />

            <Route path="/*" render={(props) => <AllRoutes {...props} />} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
