import { api } from '../../../api';
import { Button, Modal, Toast } from 'react-bootstrap';
import { useState } from 'react';

const DeleteDesignation = ({ show, hide, onDelete, designation }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const deleteDesignation = async () => {
    try {
      await api.delete(`/admin/designation/${designation.id}`);
      onDelete(designation.id);
      setToastMessage('Designation deleted successfully');
      setToastType('success');
      setShowToast(true);
      hide();
    } catch (error) {
      setToastMessage(error.response?.data?.message || 'An error occurred');
      setToastType('danger');
      setShowToast(true);
    }
  };

  return (
    <>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`bg-${toastType} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Body>
          <Button bsPrefix="btn-close" type="button" onClick={hide}>
            <span aria-hidden="true">×</span>
          </Button>
          <h5 className="mb-5">Delete Designation</h5>
          <p>Are you sure you want to delete this designation?</p>
          <Modal.Footer className="align-items-center">
            <Button variant="secondary" onClick={hide}>
              Close
            </Button>
            <Button type="submit" variant="primary" onClick={deleteDesignation}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteDesignation;
