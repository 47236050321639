import { api } from '../../../api';

import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const EditDesignation = ({ show, hide, update, designation }) => {
  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({ criteriaMode: 'all', mode: 'all' });

  const updateDesignation = async () => {
    try {
      await api.patch(`/admin/designation/${designation.id}`, {
        name: getValues('designationname'),
      });
      update(designation.id, getValues('designationname'));
      hide();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={show} onHide={hide} size="lg" centered>
      <Modal.Body>
        <Button bsPrefix="btn-close" type="button" onClick={hide}>
          <span aria-hidden="true">×</span>
        </Button>
        <h5 className="mb-5">Edit Designation</h5>
        <Form
          onSubmit={handleSubmit(updateDesignation)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(updateDesignation);
            }
          }}
        >
          <Form.Group className="mb-3">
            <Form.Label>Designation Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Type Designation Name"
              name="designationname"
              defaultValue={designation?.name}
              {...register('designationname', {
                required: 'Designation Name cannot be empty',
              })}
            />
            {errors.designationname && (
              <p className="text-danger mt-1">
                Designation Name cannot be empty
              </p>
            )}
          </Form.Group>
          <Modal.Footer className="align-items-center">
            <Button variant="secondary" onClick={hide}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Edit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditDesignation;
