import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner, Toast } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { api } from '../../api';

import RegexUtils from '../../utils/RegexUtils';

const CreateNewLead = ({ show, close, reload, setReload, stages }) => {
  const userDetails = useSelector((state) => state.userReducer.data);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [assignToError, setAssignToError] = useState(false);
  const [assignTo, setAssignTo] = useState({
    value: userDetails.fullName,
    label: userDetails.fullName,
    id: userDetails.id,
  });

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({ criteriaMode: 'all', mode: 'all' });

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const { data } = await api.get('/admin/users');
        let employees = data.data.map((employee) => {
          return {
            value: employee.fullName,
            label: employee.fullName,
            id: employee.id,
          };
        });
        setEmployeesOptions(employees);
      } catch (error) {
        setToastMessage(
          error?.response?.data?.message || 'Something went wrong try later'
        );
        setToastType('danger');
        setShowToast(true);
      }
    };
    fetchEmployees();
  }, []);

  const createLead = async () => {
    try {
      if (!assignTo?.id) return setAssignToError(true);
      setLoading(true);
      await api.post('/leads/add', {
        fullName: getValues('name'),
        companyName: getValues('companyname'),
        email: getValues('email'),
        phone: getValues('phone'),
        stageId: getValues('stage'),
        address: getValues('address'),
        selectedProcessor: assignTo.id,
      });
      setToastType('success');
      setToastMessage('Lead created successfully');
      setShowToast(true);
      setReload(!reload);
      close();
    } catch (error) {
      console.log(error);
      setToastType('danger');
      setToastMessage(
        error?.response?.data?.message || 'Something went wrong try later'
      );
      setShowToast(true);
    }
    setLoading(false);
  };
  return (
    <>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`bg-${toastType} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        onHide={close}
        centered
        size="lg"
        className="add-new-contact"
      >
        <Modal.Body>
          <Button bsPrefix="btn-close" type="button" onClick={close}>
            <span aria-hidden="true">×</span>
          </Button>
          <h5 className="mb-5">Create New Lead</h5>

          <Form
            onSubmit={handleSubmit(createLead)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(createLead);
              }
            }}
          >
            <div className="title title-xs title-wth-divider text-primary text-uppercase my-4">
              <span>Info</span>
            </div>
            <Row className="gx-2">
              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    {...register('name', {
                      required: 'Name is required',
                      pattern: {
                        value: RegexUtils.NAME,
                        message: 'Valid Full name is required',
                      },
                    })}
                    // value={fullName} onChange={e => setFullName(e.target.value)}
                    type="text"
                  />
                  {errors.name && (
                    <p className="text-danger mt-1">
                      Valid Full Name is required
                    </p>
                  )}
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    {...register('email', {
                      required: 'Email is invalid',
                      pattern: {
                        value: RegexUtils.EMAIL,
                        message: 'Email is invalid',
                      },
                    })}
                    type="email"
                  />
                  {errors.email && (
                    <p className="text-danger mt-1">Email is invalid</p>
                  )}
                </Form.Group>
              </div>
            </Row>
            <Row className="gx-3">
              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    {...register('phone', {
                      required: 'Phone ID is required',
                      pattern: {
                        value: RegexUtils.PHONE,
                        message: 'Phone number is invalid',
                      },
                    })}
                    type="text"
                  />
                  {errors.phone && (
                    <p className="text-danger mt-1">Phone number is invalid</p>
                  )}
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group className="mb-3">
                  <Form.Label>Stage</Form.Label>

                  <Form.Select {...register('stage')}>
                    {stages.map((stage, index) => (
                      <option key={index} value={stage.id}>
                        {stage.stageType}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </Row>

            <div className="title title-xs title-wth-divider text-primary text-uppercase my-4">
              <span>Company Info</span>
            </div>
            <Row className="gx-3">
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    {...register('companyname', {
                      required: 'Company Name is required',
                    })}
                    type="text"
                  />
                  {errors.companyname && (
                    <p className="text-danger mt-1">Company Name is required</p>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    {...register('address', {
                      required: 'Address is required',
                    })}
                    type="text"
                  />
                  {errors.address && (
                    <p className="text-danger mt-1">Address is required</p>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Assign To</Form.Label>
                  <Select
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: '#032390',
                        boxShadow: state.isFocused ? null : null,
                      }),
                      menuList: (provided, state) => ({
                        ...provided,
                        height: '80px',
                      }),
                    }}
                    defaultValue={{
                      value: userDetails.fullName,
                      label: userDetails.fullName,
                      id: userDetails.id,
                    }}
                    value={assignTo}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="assignTo"
                    options={employeesOptions}
                    onChange={(selectedOption) => {
                      setAssignTo(selectedOption);
                      setAssignToError(false);
                    }}
                  />
                  {assignToError && (
                    <p className="text-danger mt-1">Person Name is required</p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Modal.Footer className="align-items-center">
              {loading && (
                <div className="d-flex justify-content-center mb-2">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              <Button variant="secondary" type="button" onClick={close}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Create Lead
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateNewLead;
