import { Button } from 'react-bootstrap';
import { Edit2, Trash2 } from 'react-feather';

const DesignationBar = ({ data, showEditModal, showDeleteModal }) => {
  return (
    <>
      <li className="advance-list-item transform-none shadow-none">
        <div className="d-flex align-items-center justify-content-between">
          <div className="media align-items-center">
            <div className="media-body">
              <div className="text-dark fw-medium">{data.name}</div>
            </div>
          </div>
          <div>
            <Button
              variant="outline-primary"
              className="mnw-50p me-2"
              onClick={() => showEditModal(data)}
              aria-label="Edit Designation"
            >
              <Edit2 size={16} />
            </Button>
            <Button
              variant="outline-danger"
              className="mnw-50p"
              onClick={() => showDeleteModal(data)}
              aria-label="Delete Designation"
            >
              <Trash2 size={16} />
            </Button>
          </div>
        </div>
      </li>
    </>
  );
};

export default DesignationBar;
