import { api } from '../../../api';
import { Button, Modal, Toast } from 'react-bootstrap';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const DeleteLocationModal = ({ show, hide, index }) => {
  const companyId = useSelector((state) => state.userReducer.data.companyId);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const handleDeleteLocation = async () => {
    try {
      await api.delete(`/admin/company/${companyId}/location/${index}`);

      setToastMessage('Location deleted successfully');
      setToastType('success');
      setShowToast(true);
      window.location.reload();
      hide();
    } catch (error) {
      console.error('Error deleting location:', error);
      setToastMessage('Error deleting location');
      setToastType('danger');
      setShowToast(true);
    }
  };

  return (
    <>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`bg-${toastType} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Body>
          <Button bsPrefix="btn-close" type="button" onClick={hide}>
            <span aria-hidden="true">×</span>
          </Button>
          <h5 className="mb-5">Delete Location</h5>
          <p>Are you sure you want to delete this location?</p>
          <Modal.Footer className="align-items-center">
            <Button variant="secondary" onClick={hide}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteLocation}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteLocationModal;
