import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Plus, Download } from "react-feather";
import HkBadge from "../../../components/@hk-badge/@hk-badge";

import { api } from "../../../api";
import { useSelector } from "react-redux";

import CreateNewLead from "../CreateNewLead";
import EditLead from "../EditLead";
import LeadTableBody from "./LeadTableBody";

const LeadList = () => {
  const [reload, setReload] = useState(false);

  const [stages, setStages] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedStage, setSelectedStage] = useState("All");
  const [addNewLead, setAddNewLead] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [showEditLead, setShowEditLead] = useState(false);
  const [showDeleteLead, setShowDeleteLead] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All Leads");

  const userName = useSelector((state) => state.userReducer.data.fullName);

  useEffect(() => {
    const fetchStages = async () => {
      const { data } = await api.get("/leads/stages/all");
      setStages(data.data);
    };
    const fetchTeamMembers = async () => {
      const { data } = await api.get(`/admin/users`);
      setTeamMembers(data.data);
    };

    fetchStages();
    fetchTeamMembers();
  }, []);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        let { data } = await api.get("/leads/all");
        setLeads(data.data);
        setFilteredLeads(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLeads();
  }, [reload]);

  const onSelectStage = (stageId) => {
    setSelectedStage(stageId);
    filterLeads(stageId, selectedFilter);
  };

  const onSelectFilter = (filter) => {
    setSelectedFilter(filter);
    filterLeads(selectedStage, filter);
  };

  const filterLeads = (stageId, filter) => {
    let filtered = leads;

    if (stageId !== "All") {
      filtered = filtered.filter(
        (lead) => lead.stageId.toString() === stageId.toString()
      );
    }

    if (filter === "My Leads") {
      filtered = filtered.filter((lead) => lead.user.fullName === userName);
    }

    setFilteredLeads(filtered);
  };

  const toggleEditLead = (lead) => {
    setSelectedLead(lead);
    setShowEditLead(!showEditLead);
  };

  const toggleDeleteLead = (lead) => {
    setSelectedLead(lead);
    setShowDeleteLead(!showDeleteLead);
  };

  const downloadLeadsAsCSV = async () => {
    try {
      let { data } = await api.get("/leads/all");
      const leads = data.data;

      // Convert JSON to CSV
      const csvRows = [];
      const headers = Object.keys(leads[0]);
      csvRows.push(headers.join(",")); // Add headers row

      for (const lead of leads) {
        const values = headers.map((header) => JSON.stringify(lead[header]));
        csvRows.push(values.join(","));
      }

      const csvString = csvRows.join("\n");
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "leads.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Error downloading leads:", error);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="card-border mb-0 h-100">
            <Card.Header className="card-header-action">
              <h6>
                Leads
                <HkBadge
                  bg="light"
                  size="sm"
                  text="dark"
                  className="ms-1 fw-bold"
                >
                  {filteredLeads.length}
                </HkBadge>
              </h6>
              <div className="card-action-wrap">
                <OverlayTrigger
                  overlay={
                    stages.length === 0 ? (
                      <Tooltip id="tooltip-disabled">
                        Add stages before adding leads
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <span className="d-inline-block">
                    <Button
                      variant="primary"
                      size="sm"
                      className="ms-3"
                      onClick={() => setAddNewLead(!addNewLead)}
                      disabled={stages.length === 0}
                    >
                      <span>
                        <span className="icon">
                          <span className="feather-icon">
                            <Plus />
                          </span>
                        </span>
                        <span className="btn-text">Add new</span>
                      </span>
                    </Button>
                  </span>
                </OverlayTrigger>
                <Button
                  variant="secondary"
                  size="sm"
                  className="ms-3"
                  onClick={downloadLeadsAsCSV}
                >
                  <span>
                    <span className="icon">
                      <span className="feather-icon">
                        <Download />
                      </span>
                    </span>
                    <span className="btn-text">Download</span>
                  </span>
                </Button>
              </div>
            </Card.Header>

            <Card.Body>
              <LeadTableBody
                reload={reload}
                setReload={setReload}
                stages={[{ id: "All", stageType: "All" }, ...stages]}
                selectedStage={selectedStage}
                onSelectStage={onSelectStage}
                leads={filteredLeads}
                toggleEditLead={toggleEditLead}
                toggleDeleteLead={toggleDeleteLead}
                selectedFilter={selectedFilter}
                onSelectFilter={onSelectFilter}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CreateNewLead
        reload={reload}
        setReload={setReload}
        show={addNewLead}
        stages={stages}
        close={() => setAddNewLead(false)}
      />
      <EditLead
        show={showEditLead}
        close={() => setShowEditLead(false)}
        stages={stages}
        reload={reload}
        setReload={setReload}
        leadData={selectedLead}
        teamMembers={teamMembers}
      />

      {/* <DeleteLead
        show={showDeleteLead}
        close={() => setShowDeleteLead(false)}
        reload={reload}
        setReload={setReload}
        leadData={selectedLead}
      /> */}
    </Container>
  );
};

export default LeadList;
