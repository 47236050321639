import React, { useState } from 'react';
import classNames from 'classnames';
import LeadListHeader from '../LeadListHeader';
import ContactAppSidebar from '../LeadAppSidebar';
import LeadsCardsBody from './LeadsCardsBody';

const LeadCards = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <div className="hk-pg-body py-0">
      <div
        className={classNames('contactapp-wrap', {
          'contactapp-sidebar-toggle': showSidebar,
        })}
      >
        <ContactAppSidebar />
        <div className="contactapp-content">
          <div className="contactapp-detail-wrap">
            <LeadListHeader
              toggleSidebar={() => setShowSidebar(!showSidebar)}
              show={showSidebar}
            />
            <LeadsCardsBody />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadCards;
