import { useEffect } from "react";
import { connect } from "react-redux";
import { toggleCollapsedNav, sidebarDataHover } from "../../redux/action/Theme";

import { useSelector } from "react-redux";

import AdminDashboard from "./AdminDashboard";
import ClientDashboard from "./ClientDashboard";
import { Container } from "react-bootstrap";

const Dashboard = ({ toggleCollapsedNav, sidebarDataHover }) => {
  useEffect(() => {
    toggleCollapsedNav(false);
    sidebarDataHover(false);
  }, []);

  const user = useSelector((state) => state.userReducer.data);

  return (
    <Container>
      {user.roleName === "ADMIN" ? <AdminDashboard /> : <ClientDashboard />}
    </Container>
  );
};

// export default Dashboard
const mapStateToProps = ({ theme }) => {
  const { navCollapsed, dataHover } = theme;
  return { navCollapsed, dataHover };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  sidebarDataHover,
})(Dashboard);
