import { Container } from 'react-bootstrap';
import LatestLeads from '../LatestLeads';
import LeadStats from '../LeadStats';

const AdminDashboard = () => {
  return (
    <Container className="mt-4">
      <LatestLeads />
      <LeadStats />
    </Container>
  );
};

export default AdminDashboard;
