import { Row, Col } from 'react-bootstrap';

import LocationComponent from './Location';
import AddLocations from './AddLocations';

const Locations = ({ company }) => {
  return (
    <Row>
      <Col lg={8}>
        <Row className="gx-3">
          {company?.locations?.map((location, index) => (
            <LocationComponent key={index} location={location} index={index} />
          ))}
          <AddLocations />
        </Row>
      </Col>
    </Row>
  );
};

export default Locations;
