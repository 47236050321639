import React from 'react';
import { Button, Form, Modal, Toast } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { api } from '../../api';
import { useState } from 'react';
import { getStages } from '../../redux/action/Stages';
import { useDispatch } from 'react-redux';

const AddStage = ({ show, hide }) => {
  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({ criteriaMode: 'all', mode: 'all' });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const dispatch = useDispatch();

  const addStage = async () => {
    try {
      await api.post(`/leads/stages/add`, {
        stageType: getValues('stagename'),
        description: '',
      });

      setToastMessage('Stage added successfully');
      setToastType('success');
      setShowToast(true);
      dispatch(getStages);
    } catch (error) {
      console.log(error);
      setToastMessage(error.response?.data?.message || 'An error occurred');
      setToastType('danger');
      setShowToast(true);
    }
  };

  return (
    <>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`bg-${toastType} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Body>
          <Button bsPrefix="btn-close" type="button" onClick={hide}>
            <span aria-hidden="true">×</span>
          </Button>
          <h5 className="mb-5">Add New Stage</h5>

          <Form
            onSubmit={handleSubmit(addStage)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(addStage);
              }
            }}
          >
            <Form.Group className="mb-3">
              <Form.Label>Stage Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Stage Name"
                name="stagename"
                {...register('stagename', {
                  required: 'Stage Name is required',
                })}
              />
              {errors.stagename && (
                <p className="text-danger mt-1">Stage Name is required</p>
              )}
            </Form.Group>
            <Modal.Footer className="align-items-center">
              {/* {loading &&
                            <div className='d-flex justify-content-center mb-2'>
                                <Spinner animation="border" variant="primary" />
                            </div>
                        } */}
              <Button variant="secondary" onClick={hide}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Add
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddStage;
