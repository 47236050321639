import React, { useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Eye, EyeOff } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";

//Image
// import logoutImg from '../../../../assets/dist/img/macaroni-logged-out.png';
import RegexUtils from '../../../utils/RegexUtils';
import axios from 'axios';
import Alert from '../../../utils/Alert';
import { api } from '../../../api';

const Body = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const [showAlert, setAlertShow] = useState(false)
    const [alertData, setAlertData] = useState({
        title: '',
        msg: '',
        variant: 'danger'
    })

    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit,
    } = useForm({ criteriaMode: "all", mode: "all" });
    const handleLogin = async () => {
        // alert('hi')
        // e.preventDefault();
        // props.history.push("/");
        try {
            setLoading(true)
            const { data } = await api.post('/login', {
                email: getValues('email'),
                password: getValues('password')
            })
            localStorage.setItem('token', data.data.token)
            history.push('/dashboard');
        } catch (error) {
            let details
            error?.response?.data?.message ? details = {
                variant: 'danger',
                title: 'Error',
                msg: error.response.data.message
            } : details = {
                variant: 'danger',
                title: 'Error',
                msg: "Something went wrong try later"
            }
            setAlertData(details)
            setAlertShow(true)

        }
        setLoading(false)
    }

    return (
        <div className="hk-pg-body">
            <Alert data={alertData} show={showAlert} setShow={setAlertShow} />
            <Container>
                <Row>

                    <Col xl={5} lg={6} md={7} sm={10} className="position-relative mx-auto">
                        <div className="auth-content py-md-0 py-8">
                            <Form className="w-100" onSubmit={handleSubmit(handleLogin)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        // e.preventDefault();
                                        // handleSubmit(handleLogin);
                                    }
                                }} >
                                <Row>
                                    <Col lg={10} className="mx-auto">
                                        <h4 className="mb-4">Sign in to your account</h4>
                                        <Row className="gx-3">
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <div className="form-label-group">
                                                    <Form.Label>Email</Form.Label>
                                                </div>
                                                <Form.Control
                                                    {...register("email", {
                                                        required: "Email ID is required",
                                                        pattern: {
                                                            value: RegexUtils.EMAIL,
                                                            message: "Email is invalid",
                                                        },
                                                    })}
                                                    placeholder="Enter your email" type="text"
                                                // value={getValues('email')} onChange={e => setValue('email',e.target.value)}
                                                />
                                                {errors.email && <p className='text-danger mt-1'>Email is invalid</p>}
                                            </Col>
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <div className="form-label-group">
                                                    <Form.Label>Password</Form.Label>
                                                    <Link to="send-resetmail" className="fs-7 fw-medium">Forgot Password ?</Link>
                                                </div>
                                                <InputGroup className="password-check">
                                                    <span className="input-affix-wrapper">
                                                        <Form.Control
                                                            {...register("password", {
                                                                required: "Password is required",
                                                                pattern: {
                                                                    value: RegexUtils.PASSWORD,
                                                                    message: "Password is required",
                                                                },
                                                            })}
                                                            placeholder="Enter your password"
                                                            // value={getValues('password')}  onChange={e => setValue('password',e.target.value)} 
                                                            type={showPassword ? "text" : "password"} />

                                                        <Link to="#" className="input-suffix text-muted" onClick={() => setShowPassword(!showPassword)} >
                                                            <span className="feather-icon">
                                                                {
                                                                    showPassword
                                                                        ?
                                                                        <EyeOff className="form-icon" />
                                                                        :
                                                                        <Eye className="form-icon" />
                                                                }

                                                            </span>
                                                        </Link>
                                                    </span>
                                                </InputGroup>
                                                {errors.password && <p className='text-danger mt-1'>Invalid password</p>}

                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-center">
                                            {/* <Form.Check id="logged_in" className="form-check-sm mb-3" >
                                                <Form.Check.Input type="checkbox" defaultChecked />
                                                <Form.Check.Label className="text-muted fs-7">Keep me logged in</Form.Check.Label>
                                            </Form.Check> */}
                                        </div>
                                        {loading &&
                                            <div className='d-flex justify-content-center mb-2'>
                                                <Spinner animation="border" variant="primary" />
                                            </div>
                                        }
                                        <Button variant="primary" type="submit"
                                            className="btn-uppercase btn-block">Signin</Button>
                                        <p className="p-xs mt-2 text-center">New to LeadsDump ? <Link to="signup"><u>Create new account</u></Link></p>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Body;
