import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { api } from '../../api';
import Toast from '../Toast';

const DeleteTeamMember = ({ show, close, userData }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const handleDeleteUser = async () => {
    try {
      await api.delete(`/admin/user/${userData.id}`);
      close();
      setToastMessage('Team member deleted successfully');
      setToastType('success');
      setShowToast(true);
    } catch (error) {
      setToastMessage(error?.response?.data?.message);
      setToastType('danger');
      setShowToast(true);
    }
  };

  return (
    <>
      <Toast
        show={showToast}
        message={toastMessage}
        type={toastType}
        onClose={() => setShowToast(false)}
      />
      <Modal show={show} onHide={close} centered>
        <Modal.Body>
          <Button bsPrefix="btn-close" onClick={close}>
            <span aria-hidden="true">×</span>
          </Button>
          <h5 className="mb-4">Delete Team Member</h5>
          <p>
            Are you sure you want to remove {userData?.fullName}? This action
            will permanently delete the user.
          </p>
        </Modal.Body>
        <Modal.Footer className="align-items-center">
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteTeamMember;
