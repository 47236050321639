import AddNewPost from "../views/Blog/AddNewPost";
import PostDetail from "../views/Blog/PostDetails";
import Posts from "../views/Blog/Posts";
import Calendar from "../views/Calendar";
import Chats from "../views/Chat/Chats";
import ChatContacts from "../views/Chat/Contact";
import ChatGroups from "../views/Chat/Groups";
import LeadCards from "../views/Lead/LeadCards";
import LeadList from "../views/Lead/LeadList";
import EditLead from "../views/Lead/EditLead";
import Dashboard from "../views/Dashboard";
import Email from "../views/Email";
import GridView from "../views/FileManager/GridView";
import ListView from "../views/FileManager/ListView";
import Gallery from "../views/Gallery";
import AllApps from "../views/Integrations/All Apps";
import IntegrationsDetail from "../views/Integrations/App Details";
import Integration from "../views/Integrations/Integration";
import CreateInvoice from "../views/Invoices/CreateInvoice";
import InvoiceList from "../views/Invoices/InvoiceList";
import InvoiceTemplates from "../views/Invoices/InvoiceTemplates";
import PreviewInvoice from "../views/Invoices/PreviewInvoice";
import KanbanBoard from "../views/Scrumboard/KanbanBoard/Index";
import Pipeline from "../views/Scrumboard/Pipeline";
import ProjectsBoard from "../views/Scrumboard/ProjectsBoard";
import Gantt from "../views/Todo/Gantt";
import TaskList from "../views/Todo/Tasklist";
//Pages
import Profile from "../views/Profiles/Profile";
import EditProfile from "../views/Profiles/EditProfile";
import Account from "../views/Profiles/Account";
//Auth
import LoginSimple from "../views/Authentication/LogIn";
import SignUpSimple from "../views/Authentication/SignUp";
import SendResetMail from "../views/Authentication/SendResetMail";
import ResetPassword from "../views/Authentication/ResetPassword";

import Error404 from "../views/Authentication/Error404/Error404";
import Error503 from "../views/Authentication/Error503/Error503";
import ChatPopup from "../views/ChatPopup/DirectMessage";
import ChatBot from "../views/ChatPopup/ChatBot";
import EmployeeList from "../views/Employees/EmployeeList";
import LandingPage from "../views/LandingPage";
import Setting from "../views/Setting";
import EditEmployee from "../views/Employees/EditEmployee";

import CustomerList from "../views/Customers/CustomerList";
import TeamMembers from "../views/TeamMembers/TeamMembers";

export const allRoutes = [
  { path: "dashboard", exact: true, component: Dashboard },
  // Actual Usage Routes:
  { path: "customers", exact: true, component: CustomerList },
  { path: "team-members", exact: true, component: TeamMembers },
  //Apps
  { path: "app/chat/chats", exact: true, component: Chats },
  { path: "app/chat/chat-groups", exact: true, component: ChatGroups },
  { path: "app/chat/chat-contact", exact: true, component: ChatContacts },
  { path: "app/chat-bot/chatpopup", exact: true, component: ChatPopup },
  { path: "app/chat-bot/chatbot", exact: true, component: ChatBot },
  { path: "app/calendar", exact: true, component: Calendar },
  { path: "app/email", exact: true, component: Email },
  {
    path: "app/taskboard/projects-board",
    exact: true,
    component: ProjectsBoard,
  },

  { path: "app/taskboard/kanban-board", exact: true, component: KanbanBoard },

  { path: "app/taskboard/pipeline", exact: true, component: Pipeline },
  //leads
  { path: "app/leads/leads-list", exact: true, component: LeadList },
  { path: "app/leads/lead-cards", exact: true, component: LeadCards },
  { path: "app/leads/edit-lead", exact: true, component: EditLead },
  //employee
  { path: "app/employees/employee-list", exact: true, component: EmployeeList },
  { path: "app/employees/edit-employee", exact: true, component: EditEmployee },

  { path: "app/file-manager/list-view", exact: true, component: ListView },
  { path: "app/file-manager/grid-view", exact: true, component: GridView },
  { path: "app/gallery", exact: true, component: Gallery },
  { path: "app/todo/task-list", exact: true, component: TaskList },
  { path: "app/todo/gantt", exact: true, component: Gantt },
  { path: "app/blog/posts", exact: true, component: Posts },
  { path: "app/blog/add-new-post", exact: true, component: AddNewPost },
  { path: "app/blog/post-detail", exact: true, component: PostDetail },
  { path: "app/invoices/invoice-list", exact: true, component: InvoiceList },
  {
    path: "app/invoices/invoice-templates",
    exact: true,
    component: InvoiceTemplates,
  },
  {
    path: "app/invoices/create-invoice",
    exact: true,
    component: CreateInvoice,
  },
  {
    path: "app/invoices/invoice-preview",
    exact: true,
    component: PreviewInvoice,
  },
  { path: "app/integrations/all-app", exact: true, component: AllApps },
  {
    path: "app/integrations/integrations-detail",
    exact: true,
    component: IntegrationsDetail,
  },
  { path: "app/integrations/integration", exact: true, component: Integration },
  { path: "app/profile", exact: true, component: Profile },
  { path: "app/setting", exact: true, component: Setting },
  { path: "app/edit-profile", exact: true, component: EditProfile },
  { path: "app/account", exact: true, component: Account },
];

export const errorRoutes = [
  //Error
  { path: "/error-404", exact: true, component: Error404 },
  { path: "/error-503", exact: true, component: Error503 },
];
export const authRoutes = [
  { path: "/signin", exact: true, component: LoginSimple },
  { path: "/signup", exact: true, component: SignUpSimple },
  { path: "/send-resetmail", exact: true, component: SendResetMail },
  { path: "/reset-password/:token", exact: true, component: ResetPassword },
];
