import { useState } from 'react';
import { Col, Card, Button } from 'react-bootstrap';
import { Trash2, Minimize, Edit2 } from 'react-feather';

import EditLocationModal from './EditLocationModal';
import DeleteLocationModal from './DeleteLocationModal';

const LocationComponent = ({ location, index }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleEditLocation = () => {
    setShowEditModal(true);
  };

  const handleDeleteLocation = () => {
    setShowDeleteModal(true);
  };

  return (
    <>
      <Col md={4}>
        <Card className="card-border mnh-250p">
          <Card.Body>
            <div className="card-action-wrap">
              <Button
                size="sm"
                variant="flush-danger"
                className="btn-icon btn-rounded flush-soft-hover card-close"
                value={true}
                onClick={handleDeleteLocation}
              >
                <span className="icon">
                  <span className="feather-icon">
                    <Trash2 />
                  </span>
                </span>
              </Button>
              <Button
                variant="flush-dark"
                size="sm"
                className="btn-icon btn-rounded flush-soft-hover"
                onClick={handleEditLocation}
              >
                <span className="icon">
                  <span className="feather-icon">
                    <Edit2 />
                  </span>
                </span>
              </Button>
            </div>
            <i className="bi bi-house-door-fill fs-3 d-block mb-1 text-primary" />
            <h5>{location?.city}</h5>
            <Card.Text>{`${location?.state}, ${location?.country}`}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <EditLocationModal
        show={showEditModal}
        hide={() => setShowEditModal(false)}
        location={location}
        index={index}
      />
      <DeleteLocationModal
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        index={index}
      />
    </>
  );
};

export default LocationComponent;
