import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { Edit, Trash, Star } from 'react-feather';
import { ArrowsSort, SortAscending, SortDescending } from 'tabler-icons-react';
import HkBadge from '../../components/@hk-badge/@hk-badge';
import AvatarLetters from '../../utils/AvatarLetters';

const customCaret = (order, column) => {
  if (!order)
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp; <ArrowsSort size={14} strokeWidth={2.5} />{' '}
      </span>
    );
  else if (order === 'asc')
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <font color="#032390">
          <SortAscending size={14} strokeWidth={2.5} />
        </font>
      </span>
    );
  else if (order === 'desc')
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <font color="#032390">
          <SortDescending size={14} strokeWidth={2.5} />
        </font>
      </span>
    );
  return null;
};

const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) =>
  sortOrder === 'asc' ? 'text-primary' : 'text-primary';

const nameFormatter = (cell, row) => {
  const name = `${row.firstName} ${row.lastName}`;
  let avt = AvatarLetters(name);
  return (
    <div className="media align-items-center">
      <div className="media-head me-2">
        <div
          className={classNames(
            'avatar avatar-xs avatar-rounded',
            'avatar-soft-info'
          )}
        >
          <span className="initial-wrap">{avt}</span>
        </div>
      </div>
      <div className="media-body">
        <span className="d-block text-high-em">{name}</span>
        <small className="d-block text-muted">{row.companyName}</small>
      </div>
    </div>
  );
};
const tagsFormatter = (cell) => (
  <div className="d-flex flex-wrap">
    {cell?.map((tag, idx) => (
      <span key={idx} className="badge badge-soft-primary me-1 mb-1">
        {tag}
      </span>
    ))}
  </div>
);

const ratingFormatter = (cell) => (
  <div className="d-flex align-items-center">
    <Star size={14} className="text-warning me-1" fill="#ffc107" />
    {/* <span className="badge bg-secondary">{cell}</span> */}
    <span className="text-muted">{cell}</span>
  </div>
);

const statusFormatter = (cell) => (
  <HkBadge bg={cell ? 'success' : 'danger'} className="ms-1">
    {cell ? 'Active' : 'Inactive'}
  </HkBadge>
);


export const getColumns = (handleEditClick, handleDeleteClick, team) => {
  return [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: nameFormatter,
      sortCaret: customCaret,
      headerSortingClasses,
    },
    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
      sortCaret: customCaret,
      headerSortingClasses,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      sortCaret: customCaret,
      headerSortingClasses,
    },
    {
      dataField: 'tags',
      text: 'Tags',
      formatter: tagsFormatter,
      sort: true,
      sortCaret: customCaret,
      headerSortingClasses,
    },
    {
      dataField: 'location',
      text: 'Location',
      sort: true,
      sortCaret: customCaret,
      headerSortingClasses,
    },
    {
      dataField: 'rating',
      text: 'Rating',
      formatter: ratingFormatter,
      sort: true,
      sortCaret: customCaret,
      headerSortingClasses,
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: statusFormatter,
      sort: true,
      sortCaret: customCaret,
      headerSortingClasses,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell, row) => (
        <div className="d-flex">
          <Button
            variant="flush-dark"
            className="btn-icon btn-rounded flush-soft-hover"
            onClick={() => handleEditClick(row)}
          >
            <span className="icon">
              <span className="feather-icon">
                <Edit />
              </span>
            </span>
          </Button>
          <Button
            variant="flush-dark"
            className="btn-icon btn-rounded flush-soft-hover del-button"
            onClick={() => handleDeleteClick(row)}
          >
            <span className="icon">
              <span className="feather-icon">
                <Trash />
              </span>
            </span>
          </Button>
        </div>
      ),
    },
  ];
};
