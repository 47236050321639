import * as Icons from "tabler-icons-react";
import { NavLink } from 'react-router-dom';

export const NavMenu = [
  {
    group: "",
    contents: [
      {
        name: "Dashboard",
        icon: <Icons.Template />,
        path: "/dashboard",
        component: NavLink,
        exact: true,
      },
      {
        name: "Customers",
        icon: <Icons.Users />,
        path: "/customers",
        component: NavLink,
        exact: true,
      },
      {
        name: "Team Members",
        icon: <Icons.UserPlus />,
        path: "/team-members",
        component: NavLink,
        exact: true,
      },
      {
        name: "Leads",
        icon: <Icons.Inbox />,
        path: "/app/leads/leads-list",
        component: NavLink,
        exact: true,
        grp_name: "app",
      },
      {
        name: "Calendar",
        icon: <Icons.Calendar />,
        path: "/calendar",
        component: NavLink,
        exact: true,
      },
    ],
  },
];

// export const NavMenu = [
//   {
//     group: "",
//     contents: [
//       {
//         name: "Dashboard",
//         icon: <Icons.Template />,
//         path: "/dashboard",
//         // badge: <HkBadge size="sm" bg="pink" soft className="ms-xl-2 ms-auto" >hot</HkBadge>
//       },
//     ],
//   },
//   {
//     group: "app",
//     contents: [
//       {
//         name: "Leads",
//         icon: <Icons.Inbox />,
//         path: "/app/leads/leads-list",
//         grp_name: "app",
//       },
//       {
//         name: "Customers",
//         icon: <Icons.Users />,
//         path: "#",
//       },
//       {
//         name: "Team Members",
//         icon: <Icons.UserPlus />,
//         path: "#",
//       },
//       {
//         name: "Calendar",
//         icon: <Icons.Calendar />,
//         path: "#",
//       },
//     ],
//   },

//   {
//     group: "Pages",
//     contents: [
//       // {
//       //     id: "dash_pages",
//       //     name: 'Authentication',
//       //     icon: <Icons.UserPlus />,
//       //     path: '/auth',
//       //     childrens: [
//       //         {
//       //             id: "dash_log",
//       //             name: 'Log In',
//       //             path: '/auth',
//       //             childrens: [
//       //                 // {
//       //                 //     name: 'Login',
//       //                 //     path: '/auth/login',
//       //                 // },
//       //                 {
//       //                     name: 'Login Simple',
//       //                     path: '/auth/login',
//       //                 }
//       //                 ,
//       //                 // {
//       //                 //     name: 'Login Classic',
//       //                 //     path: '/auth/login-classic',
//       //                 // },
//       //             ]
//       //         },
//       //         {
//       //             id: "dash_sign",
//       //             name: 'Sign Up',
//       //             path: '/auth',
//       //             childrens: [
//       //                 // {
//       //                 //     name: 'Signup',
//       //                 //     path: '/auth/signup',
//       //                 // },
//       //                 {
//       //                     name: 'Signup Simple',
//       //                     path: '/auth/signup',
//       //                 },
//       //                 // {
//       //                 //     name: 'Signup Classic',
//       //                 //     path: '/auth/signup-classic',
//       //                 // },
//       //             ]
//       //         },
//       //         {
//       //             name: 'Lock Screen',
//       //             path: '/auth/lock-screen',
//       //         },
//       //         {
//       //             name: 'Reset Password',
//       //             path: '/auth/reset-password',
//       //         },
//       //         {
//       //             name: 'Error 404',
//       //             path: '/error-404',
//       //         },
//       //         {
//       //             name: 'Error 503',
//       //             path: '/auth/error-503',
//       //         },
//       //     ]
//       // },
//       // {
//       //     id: "dash_profile",
//       //     name: 'Profile',
//       //     icon: <Icons.UserSearch />,
//       //     path: '/app',
//       //     badgeIndicator: <HkBadge bg="danger" indicator className="position-absolute top-0 start-100" />,
//       //     childrens: [
//       //         {
//       //             name: 'Profile',
//       //             path: '/app/profile',
//       //             grp_name: "app",
//       //         },
//       //         {
//       //             name: 'Edit Profile',
//       //             path: '/app/edit-profile',
//       //             grp_name: "app",
//       //         },
//       //         {
//       //             name: 'Account',
//       //             path: '/app/account',
//       //             grp_name: "app",
//       //         },
//       //     ]
//       // },
//     ],
//   },

//   {
//     group: "Documentation",
//     contents: [
//       // {
//       //     name: 'Documentation',
//       //     icon: <Icons.FileCode2 />,
//       //     path: 'https://nubra-ui-react.netlify.app/introduction',
//       // },
//       // {
//       //     name: 'Components',
//       //     icon: <Icons.Layout />,
//       //     path: 'https://nubra-ui-react.netlify.app/avatar',
//       // },
//     ],
//   },
// ];
