import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TeamMembersTable from './TeamMembersTable';
import { api } from '../../api';

const TeamMembers = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const response = await api.get(`/admin/users`);
      setTeamMembers(response.data.data);
    };
    const fetchDesignations = async () => {
      const response = await api.get(`/admin/designations`);
      setDesignations(response.data.data);
    };
    fetchTeamMembers();
    fetchDesignations();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col>
          <TeamMembersTable data={teamMembers} designations={designations} />
        </Col>
      </Row>
    </Container>
  );
};

export default TeamMembers;
