import { Container } from 'react-bootstrap';
import LatestLeads from '../LatestLeads';
import TodoList from '../TodoList';

const ClientDashboard = () => {
  return (
    <Container className="mt-4">
      <LatestLeads />
      <TodoList />
    </Container>
  );
};

export default ClientDashboard;
