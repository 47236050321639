import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner, Toast } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { api } from '../../api';

const EditCustomer = ({ show, close, customerData, onEdit }) => {
  const companyId = useSelector((state) => state.userReducer.data.companyId);

  const [formData, setFormData] = useState({
    firstName: customerData?.firstName || '',
    lastName: customerData?.lastName || '',
    jobTitle: customerData?.jobTitle || '',
    companyName: customerData?.companyName || '',
    industry: customerData?.industry || '',
    email: customerData?.email || '',
    phone: customerData?.phone || '',
    phone2: customerData?.phone2 || '',
    website: customerData?.website || '',
    location: customerData?.location || '',
    currency: customerData?.currency || '',
    language: customerData?.language || '',
    description: customerData?.description || '',
    tags: customerData?.tags || [],
    rating: customerData?.rating || 0.0,
    status: customerData?.status ?? true,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  useEffect(() => {
    if (customerData) {
      setFormData({
        firstName: customerData.firstName || '',
        lastName: customerData.lastName || '',
        jobTitle: customerData.jobTitle || '',
        companyName: customerData.companyName || '',
        industry: customerData.industry || '',
        email: customerData.email || '',
        phone: customerData.phone || '',
        phone2: customerData.phone2 || '',
        website: customerData.website || '',
        location: customerData.location || '',
        currency: customerData.currency || '',
        language: customerData.language || '',
        description: customerData.description || '',
        tags: customerData.tags || [],
        rating: customerData?.rating || 0.0,
        status: customerData?.status ?? true,
      });
    }
  }, [customerData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone' || name === 'phone2') {
      const numbersOnly = value.replace(/[^\d]/g, '');
      setFormData({ ...formData, [name]: numbersOnly });
      return;
    }

    if (name === 'status') {
      setFormData({ ...formData, [name]: value === 'true' });
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleTagsChange = (e) => {
    const tagsArray = e.target.value.split(',').map((tag) => tag.trim());
    setFormData({ ...formData, tags: tagsArray });
  };

  const handleRatingChange = (e) => {
    const value = e.target.value;
    if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 5)) {
      setFormData({ ...formData, rating: parseFloat(value) || 0 });
    }
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.website && !isValidUrl(formData.website)) {
      setToastMessage(
        'Please enter a valid website URL (e.g., https://example.com)'
      );
      setToastType('danger');
      setShowToast(true);
      return;
    }

    setIsLoading(true);

    try {
      await api.patch(`/customers/${customerData.id}`, {
        ...formData,
        companyId,
      });

      onEdit({
        ...formData,
        id: customerData.id,
      });

      setToastMessage('Customer updated successfully!');
      setToastType('success');
      setShowToast(true);
      close();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        'An error occurred while updating the customer.';
      setToastMessage(errorMessage);
      setToastType('danger');
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={close} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Industry</Form.Label>
                  <Form.Control
                    type="text"
                    name="industry"
                    value={formData.industry}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="Enter numbers only"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Rating (0-5)</Form.Label>
                  <Form.Control
                    type="number"
                    name="rating"
                    value={formData.rating}
                    onChange={handleRatingChange}
                    min="0"
                    max="5"
                    step="0.1"
                    required
                  />
                  <Form.Text className="text-muted">
                    Enter a value between 0 and 5
                  </Form.Text>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Secondary Phone (Optional)</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone2"
                    value={formData.phone2}
                    onChange={handleChange}
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="Enter numbers only"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Website (Optional)</Form.Label>
                  <Form.Control
                    type="url"
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                    placeholder="https://example.com"
                    pattern="https?://.*"
                    title="Please include http:// or https://"
                  />
                  <Form.Text className="text-muted">
                    Please include http:// or https:// in the URL
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    type="text"
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                    type="text"
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Tags (Comma separated)</Form.Label>
                  <Form.Control
                    type="text"
                    name="tags"
                    value={formData.tags.join(', ')}
                    onChange={handleTagsChange}
                    placeholder="e.g., VIP, Enterprise, New Lead"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name="status"
                    value={formData.status.toString()}
                    onChange={handleChange}
                    required
                  >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Description (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="text-end mt-3">
              <Button variant="secondary" onClick={close} className="me-2">
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />
                    Updating Customer...
                  </>
                ) : (
                  'Update Customer'
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`bg-${toastType} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </>
  );
};

export default EditCustomer;
