import { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { api } from '../../../api';

import DesignationBar from './DesignationBar';
import AddDesignation from './AddDesignationModal';
import EditDesignation from './EditDesignation';
import DeleteDesignation from './DeleteDesignation';

const Designations = () => {
  // States
  const [designations, setDesignations] = useState([]);
  const [showAddDesignation, setShowAddDesignation] = useState(false);
  const [showEditDesignation, setShowEditDesignation] = useState(false);
  const [showDeleteDesignation, setShowDeleteDesignation] = useState(false);
  const [currentDesignation, setCurrentDesignation] = useState(null);

  // Use Effect to fetch designations
  useEffect(() => {
    const fetchDesignations = async () => {
      const { data } = await api.get('/admin/designations');
      setDesignations(data.data);
    };
    fetchDesignations();
  }, []);

  // Callbacks
  const hideAddModal = () => {
    setShowAddDesignation(false);
  };
  const showAddModal = () => {
    setShowAddDesignation(true);
  };

  const showEditModal = (designation) => {
    setCurrentDesignation(designation);
    setShowEditDesignation(true);
  };
  const hideEditModal = () => {
    setCurrentDesignation(null);
    setShowEditDesignation(false);
  };

  const showDeleteModal = (designation) => {
    setCurrentDesignation(designation);
    setShowDeleteDesignation(true);
  };
  const hideDeleteModal = () => {
    setCurrentDesignation(null);
    setShowDeleteDesignation(false);
  };

  const addDesginationToState = (designation) => {
    setDesignations([
      ...designations,
      {
        id: designations.length + 1,
        name: designation,
      },
    ]);
  };

  const updateDesignationInState = (id, name) => {
    setDesignations(
      designations.map((designation) =>
        designation.id === id ? { ...designation, name } : designation
      )
    );
  };

  const deleteDesignationFromState = (id) => {
    setDesignations(
      designations.filter((designation) => designation.id !== id)
    );
  };

  return (
    <>
      <Row>
        <Col md={8}>
          <div className="title-lg fs-5 justify-content-between mb-5">
            <span>Designations</span>
            <Button
              variant="primary"
              className="flush-soft-hover"
              onClick={() => {
                showAddModal();
              }}
            >
              Add Designation
            </Button>
          </div>

          <ul className="advance-list">
            {designations.map((designation, index) => (
              <DesignationBar
                key={index}
                data={designation}
                showEditModal={showEditModal}
                showDeleteModal={showDeleteModal}
              />
            ))}
          </ul>
        </Col>
      </Row>
      <AddDesignation
        show={showAddDesignation}
        hide={hideAddModal}
        add={addDesginationToState}
      />
      <EditDesignation
        show={showEditDesignation}
        hide={hideEditModal}
        update={updateDesignationInState}
        designation={currentDesignation}
      />
      <DeleteDesignation
        show={showDeleteDesignation}
        hide={hideDeleteModal}
        onDelete={deleteDesignationFromState}
        designation={currentDesignation}
      />
    </>
  );
};

export default Designations;
