import { useState, useEffect } from 'react';
import { Row, Col, Form, Toast } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { api } from '../../../api';
import DetailItem from './Details';

const Details = ({ company }) => {
  const [formData, setFormData] = useState(company);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const companyId = useSelector((state) => state.userReducer.data.companyId);

  const handleSave = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  useEffect(() => {
    setFormData(company);
  }, [company]);

  const handleSubmit = async () => {
    try {
      await api.patch(`/admin/company/${companyId}`, {
        data: formData,
      });
      setToastMessage('Company details updated successfully');
      setToastType('success');
    } catch (error) {
      setToastMessage('Error updating company details');
      setToastType('danger');
    } finally {
      setShowToast(true);
    }
  };

  const renderToast = () => (
    <Toast
      show={showToast}
      onClose={() => setShowToast(false)}
      delay={3000}
      autohide
      className={`bg-${toastType} text-white`}
      style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
    >
      <Toast.Body>{toastMessage}</Toast.Body>
    </Toast>
  );

  return (
    <>
      {renderToast()}
      {formData && (
        <Row>
          <Col md={8}>
            <div className="title-lg fs-5">
              <span>Company Details</span>
            </div>
            <Form>
              {[
                'name',
                'industry',
                'website',
                'contactNumber',
                'address',
                'description',
                'incorporationDate',
                'facebook',
                'twitter',
                'linkedin',
                'instagram',
              ].map((field) => (
                <DetailItem
                  key={field}
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  value={formData[field]}
                  onSave={(value) => handleSave(field, value)}
                />
              ))}
            </Form>
            <div className="text-end mt-6">
              <button
                onClick={handleSubmit}
                className="btn btn-primary btn-rounded"
              >
                Save changes
              </button>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Details;
