import { useMemo, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Pagination,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Plus } from 'react-feather';
import { useSelector } from 'react-redux';
import HkBadge from '../../components/@hk-badge/@hk-badge';

import AddTeamMember from './AddTeamMember';
import DeleteTeamMember from './DeleteTeamMember';
import EditTeamMember from './EditTeamMember';

//Table Data

import { getColumns } from './data';
//Table search
const { SearchBar } = Search;

const TeamMembersTable = ({ data, designations }) => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowEditModal = () => setEditModal(true);
  const handleCloseEditModal = () => setEditModal(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const userRole = useSelector((state) => state.userReducer.data.roleName);

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  //Custom Total layout
  const customTotal = (from, to, size) => (
    <span className="ms-2">
      {from} - {to} of {size}
    </span>
  );

  //Custom Select Box
  const selectRow = {
    mode: 'checkbox',
    selectionHeaderRenderer: ({ mode, indeterminate, ...rest }) => {
      return (
        <Form.Check
          type={mode}
          className="fs-6 mb-0"
          ref={(input) => {
            if (input) input.indeterminate = false;
          }}
          {...rest}
          readOnly
        />
      );
    },
    selectionRenderer: ({ mode, checked }) => {
      // console.log(rest);
      return <Form.Check type={mode} checked={checked} readOnly />;
    },
    style: { backgroundColor: '#ebf5f5', color: 'black' },
  };

  // Options for Table Paginations
  const handleNextPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page + 1);
    };

  const handlePrevPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page - 1);
    };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    handleShowEditModal();
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => setDeleteModal(false);

  const columns = useMemo(() => {
    const baseColumns = getColumns(handleEditClick, handleDeleteClick);

    if (userRole !== 'ADMIN') {
      return baseColumns.filter((column) => column.dataField !== 'actions');
    }

    return baseColumns;
  }, [userRole]);

  // Options for Table Paginations
  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    withFirstAndLast: false,
    sizePerPage: 5,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    totalSize: data.length,
  };

  const isButtonDisabled = designations.length === 0;

  return (
    <Card className="card-border mb-0 h-100">
      <Card.Header className="card-header-action">
        <h6>
          Team Members
          <HkBadge bg="light" size="sm" text="dark" className="ms-1 fw-bold">
            {data.length}
          </HkBadge>
        </h6>
        <div className="card-action-wrap">
          {userRole === 'ADMIN' &&
            (isButtonDisabled ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Complete adding designations in company settings
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <Button
                    variant="primary"
                    size="sm"
                    className="ms-3"
                    onClick={handleShowModal}
                    disabled
                    style={{ pointerEvents: 'none' }}
                  >
                    <span>
                      <span className="icon">
                        <span className="feather-icon">
                          <Plus />
                        </span>
                      </span>
                      <span className="btn-text">Add new</span>
                    </span>
                  </Button>
                </span>
              </OverlayTrigger>
            ) : (
              <Button
                variant="primary"
                size="sm"
                className="ms-3"
                onClick={handleShowModal}
              >
                <span>
                  <span className="icon">
                    <span className="feather-icon">
                      <Plus />
                    </span>
                  </span>
                  <span className="btn-text">Add new</span>
                </span>
              </Button>
            ))}
        </div>
      </Card.Header>
      <Card.Body>
        <div className="contact-list-view">
          {data.length === 0 ? (
            <div className="text-center">
              <p>No team members available.</p>
            </div>
          ) : (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={data}
              search
              columnToggle
            >
              {(props) => (
                <>
                  <PaginationProvider pagination={paginationFactory(options)}>
                    {({ paginationProps, paginationTableProps }) => (
                      <>
                        <Row className="mb-3">
                          <Col sm={8}></Col>
                          <Col
                            sm={4}
                            className="text-right d-flex justify-content-end"
                          >
                            <SearchBar
                              {...props.searchProps}
                              className="form-control form-control-sm"
                              type="search"
                            />
                          </Col>
                        </Row>
                        <BootstrapTable
                          keyField="id"
                          data={data}
                          columns={columns}
                          selectRow={selectRow}
                          bordered={false}
                          classes="dataTable display pb-30"
                          wrapperClasses="table-responsive scrollable-able"
                          // pagination={paginationFactory(options)}
                          {...paginationTableProps}
                          defaultSorted={defaultSorted}
                          {...props.baseProps}
                        />

                        <Row>
                          <Col>
                            <PaginationTotalStandalone {...paginationProps} />
                          </Col>
                          <Col>
                            <Pagination className="custom-pagination pagination-simple pagination-sm d-flex justify-content-end">
                              <Pagination.Prev
                                onClick={handlePrevPage(paginationProps)}
                                disabled={paginationProps.page === 1}
                              >
                                {' '}
                                <i className="ri-arrow-left-s-line" />{' '}
                              </Pagination.Prev>
                              <Pagination.Item active>
                                {paginationProps.page}
                              </Pagination.Item>
                              <Pagination.Next
                                onClick={handleNextPage(paginationProps)}
                                disabled={
                                  paginationProps.page >=
                                  paginationProps.dataSize /
                                    paginationProps.paginationSize
                                }
                              >
                                {' '}
                                <i className="ri-arrow-right-s-line" />{' '}
                              </Pagination.Next>
                            </Pagination>
                          </Col>
                        </Row>
                      </>
                    )}
                  </PaginationProvider>
                </>
              )}
            </ToolkitProvider>
          )}
        </div>
      </Card.Body>
      <AddTeamMember
        show={showModal}
        close={handleCloseModal}
        designations={designations}
      />
      <EditTeamMember
        show={editModal}
        close={handleCloseEditModal}
        userData={selectedUser}
        designations={designations}
      />
      <DeleteTeamMember
        show={deleteModal}
        close={handleCloseDeleteModal}
        userData={selectedUser}
      />
    </Card>
  );
};

export default TeamMembersTable;
