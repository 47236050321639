import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Plus } from 'react-feather';
import HkBadge from '../../components/@hk-badge/@hk-badge';

import { api } from '../../api';

import AddCustomer from './AddCustomer';
import CustomerTable from './CustomersTable';
import DeleteCustomer from './DeleteCustomer';
import EditCustomer from './EditCustomers';

const CustomerList = () => {
  const isAdmin = useSelector((state) => state.userReducer.data.roleName);

  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const [showDeleteCustomer, setShowDeleteCustomer] = useState(false);
  const [customerData, setCustomerData] = useState(null);

  // Fetch customers and team members
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [customersResponse] = await Promise.all([api.get('/customers/')]);

        setCustomers(customersResponse.data.data);
        setFilteredCustomers(customersResponse.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Sorting functions
  const sortActiveCustomers = () => {
    setFilteredCustomers(
      customers.filter((customer) => customer.status === true)
    );
  };

  const sortInactiveCustomers = () => {
    setFilteredCustomers(
      customers.filter((customer) => customer.status === false)
    );
  };

  const showAllCustomers = () => {
    setFilteredCustomers(customers);
  };

  // Toggle functions
  const toggleAddCustomer = () => {
    setShowAddCustomer(!showAddCustomer);
  };

  const toggleEditCustomer = (customer) => {
    if (customer) {
      setCustomerData(customer);
      setShowEditCustomer(true);
    } else {
      setCustomerData(null);
      setShowEditCustomer(false);
    }
  };

  const toggleDeleteCustomer = (customer) => {
    setShowDeleteCustomer(!showDeleteCustomer);
    setCustomerData(customer);
  };

  const onAdd = (customer) => {
    setCustomers([...customers, customer]);
  };

  const onEdit = (updatedCustomer) => {
    console.log(updatedCustomer);
    const updatedCustomers = customers.map((customer) =>
      customer.id === updatedCustomer.id ? updatedCustomer : customer
    );

    setCustomers(updatedCustomers);

    if (filteredCustomers.length !== customers.length) {
      setFilteredCustomers(
        updatedCustomers.filter(
          (customer) => customer.status === filteredCustomers[0]?.status
        )
      );
    } else {
      setFilteredCustomers(updatedCustomers);
    }
  };

  const onDelete = (id) => {
    const updatedCustomers = customers.filter((customer) => customer.id !== id);
    setCustomers(updatedCustomers);

    if (filteredCustomers.length !== customers.length) {
      setFilteredCustomers(
        updatedCustomers.filter(
          (customer) => customer.status === filteredCustomers[0]?.status
        )
      );
    } else {
      setFilteredCustomers(updatedCustomers);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="card-border mb-0 h-100">
            <Card.Header className="card-header-action">
              <h6>
                Customer List
                <HkBadge
                  bg="light"
                  size="sm"
                  text="dark"
                  className="ms-1 fw-bold"
                >
                  {customers?.length}
                </HkBadge>
              </h6>
              {isAdmin === 'ADMIN' && (
                <div className="card-action-wrap">
                  <Button variant="primary" size="sm" className="ms-3">
                    <span>
                      <span className="icon">
                        <span className="feather-icon">
                          <Plus />
                        </span>
                      </span>
                      <span className="btn-text" onClick={toggleAddCustomer}>
                        Add new
                      </span>
                    </span>
                  </Button>
                </div>
              )}
            </Card.Header>
            <Card.Body>
              <CustomerTable
                customers={filteredCustomers}
                sortActiveCustomers={sortActiveCustomers}
                sortInactiveCustomers={sortInactiveCustomers}
                showAllCustomers={showAllCustomers}
                toggleEditCustomer={toggleEditCustomer}
                toggleDeleteCustomer={toggleDeleteCustomer}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddCustomer
        show={showAddCustomer}
        close={toggleAddCustomer}
        onAdd={onAdd}
      />
      <EditCustomer
        show={showEditCustomer}
        close={() => {
          setShowEditCustomer(false);
          setCustomerData(null);
        }}
        customerData={customerData}
        onEdit={onEdit}
      />
      <DeleteCustomer
        show={showDeleteCustomer}
        close={() => {
          setShowDeleteCustomer(false);
          setCustomerData(null);
        }}
        customerData={customerData}
        onDelete={onDelete}
      />
    </Container>
  );
};

export default CustomerList;
