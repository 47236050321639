import { useEffect, useState } from 'react';
import { Badge, Card, Col, Container, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { api } from '../../api';

const LatestLeads = () => {
  const [leads, setLeads] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(5);

  const columns = [
    {
      dataField: 'fullName',
      text: 'Lead Name',
      sort: true,
    },
    {
      dataField: 'companyName',
      text: 'Company Name',
      sort: true,
    },
    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
    },
    {
      dataField: 'companyLeadStage.stageType',
      text: 'Lead Status',
      sort: true,
      formatter: (cell) => <Badge bg="primary">{cell}</Badge>,
    },
  ];

  const handleSizePerPageChange = (e) => {
    setSizePerPage(Number(e.target.value));
  };

  const paginationOptions = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPage: sizePerPage,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    totalSize: leads.length,
  };

  useEffect(() => {
    async function fetchLatestLeads() {
      const response = await api.get(`/leads/latest?num=${sizePerPage}`);
      setLeads(response.data.data);
    }
    fetchLatestLeads();
  }, [sizePerPage]);

  return (
    <Card
      className="mb-3 mx-auto"
      style={{ borderRadius: '10px', width: '90%' }}
    >
      <Card.Header>
        <Container>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Recently Created Leads</h5>
            </Col>
            <Col className="text-end">
              <Form.Select
                size="sm"
                style={{ width: 'auto', display: 'inline-block' }}
                onChange={handleSizePerPageChange}
                value={sizePerPage}
              >
                {[5, 10, 15, 20, 25, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Container>
      </Card.Header>
      <Card.Body>
        {leads.length > 0 ? (
          <BootstrapTable
            keyField="id"
            data={leads}
            columns={columns}
            pagination={paginationFactory(paginationOptions)}
          />
        ) : (
          <p className="text-center">No leads available.</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default LatestLeads;
