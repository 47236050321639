import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Dropdown, Toast } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../api';
import { getStages } from '../../redux/action/Stages';

import AddStage from './AddStage';
import RenameStage from './RenameStage';

const Body = () => {
  const stages = useSelector((state) => state.stagesReducer.stages);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [uiState, setUiState] = useState({
    showUpdateBtn: 'none',
    showRename: false,
    showAddStage: false,
    renameStageIndex: 0,
  });
  const [toast, setToast] = useState({
    show: false,
    message: '',
    type: 'success',
  });

  useEffect(() => {
    dispatch(getStages);
  }, [dispatch]);

  useEffect(() => {
    if (stages && stages.length > 0) {
      setItems(stages.slice(0, stages.length));
    }
  }, [stages]);

  const reorder = (startIndex, endIndex) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((ele, index) => ({ ...ele, order: index }));
  };

  const onDragEnd = (result) => {
    if (!result.destination || result.source.index === result.destination.index)
      return;
    const reorderedItems = reorder(
      result.source.index,
      result.destination.index
    );
    setUiState((prev) => ({ ...prev, showUpdateBtn: 'flex' }));
    setItems(reorderedItems);
  };

  const showToastMessage = (message, type = 'success') => {
    setToast({ show: true, message, type });
  };

  const updateStages = async () => {
    try {
      await api.patch(`/leads/stages/edit`, { data: items });
      showToastMessage('Stage Updated');
      dispatch(getStages);
    } catch (error) {
      showToastMessage(
        error?.response?.data?.message || 'Something went wrong try later',
        'danger'
      );
    }
    setUiState((prev) => ({ ...prev, showUpdateBtn: 'none' }));
  };

  const renameStage = (index) => {
    setUiState((prev) => ({
      ...prev,
      showRename: true,
      renameStageIndex: index,
    }));
  };

  const deleteStage = async (id) => {
    try {
      const response = await api.delete(`/leads/stages/${id}`);
      if (response.data) {
        setItems(items.filter((item) => item.id !== id));
        showToastMessage('Stage deleted successfully');
      }
    } catch (error) {
      showToastMessage(
        error?.response?.data?.message || 'Something went wrong try later',
        'danger'
      );
    }
  };
  return (
    <div className="w-lg-50 ms-lg-5">
      <Toast
        show={toast.show}
        onClose={() => setToast((prev) => ({ ...prev, show: false }))}
        delay={3000}
        autohide
        className={`bg-${toast.type} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toast.message}</Toast.Body>
      </Toast>

      <RenameStage
        show={uiState.showRename}
        renameStageIndex={uiState.renameStageIndex}
        hide={() => setUiState((prev) => ({ ...prev, showRename: false }))}
        items={items}
        setItems={setItems}
        callBack={updateStages}
      />

      <AddStage
        show={uiState.showAddStage}
        hide={() => setUiState((prev) => ({ ...prev, showAddStage: false }))}
      />

      <div className="title-lg fs-5 justify-content-between mb-5">
        <span>Lead Stages</span>
        <Button
          variant="primary"
          className="flush-soft-hover"
          onClick={() =>
            setUiState((prev) => ({ ...prev, showAddStage: true }))
          }
        >
          Add Stage
        </Button>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className=""
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className={classNames(
                        ' align-items-center  advance-list-item single-task-list active-todo my-3',
                        { selected: true }
                      )}
                      key={item.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="todo-text text-dark text-truncate">
                              {item.stageType}
                            </span>
                          </div>
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="flush-light"
                            className="btn-icon btn-rounded flush-soft-hover no-caret"
                          >
                            <span className="icon">
                              <span className="feather-icon">
                                <MoreVertical />
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Dropdown.Item
                              className="edit-task"
                              onClick={() => renameStage(index)}
                            >
                              Rename Stage
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="view-task"
                              onClick={() => deleteStage(item.id)}
                            >
                              Delete Stage
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className={`d-${uiState.showUpdateBtn} mx-5  justify-content-end`}>
        <Button
          variant="light"
          className="mx-5"
          onClick={() => {
            setItems(() => stages.slice(0, stages.length - 2));
            setUiState((prev) => ({ ...prev, showUpdateBtn: 'none' }));
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={updateStages}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default Body;
