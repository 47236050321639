import { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner, Toast } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { api } from '../../api';

const AddCustomer = ({ show, close, onAdd }) => {
  const companyId = useSelector((state) => state.userReducer.data.companyId);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    companyName: '',
    industry: '',
    email: '',
    phone: '',
    phone2: '',
    website: '',
    location: '',
    currency: '',
    language: '',
    description: '',
    tags: [],
    status: true,
    rating: 0.0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Special handling for phone numbers - allow only digits
    if (name === 'phone' || name === 'phone2') {
      const numbersOnly = value.replace(/[^\d]/g, '');
      setFormData({ ...formData, [name]: numbersOnly });
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleTagsChange = (e) => {
    // Convert comma-separated string to array
    const tagsArray = e.target.value.split(',').map((tag) => tag.trim());
    setFormData({ ...formData, tags: tagsArray });
  };

  const handleRatingChange = (e) => {
    const value = e.target.value;
    if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 5)) {
      setFormData({ ...formData, rating: value });
    }
  };

  // Website validation function
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate website if provided
    if (formData.website && !isValidUrl(formData.website)) {
      setToastMessage(
        'Please enter a valid website URL (e.g., https://example.com)'
      );
      setToastType('danger');
      setShowToast(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await api.post('/customers/create', {
        ...formData,
        companyId,
      });
      onAdd(response.data.data);
      setToastMessage('Customer added successfully!');
      setToastType('success');
      setShowToast(true);
      close();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        'An error occurred while adding the customer.';
      setToastMessage(errorMessage);
      setToastType('danger');
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={close} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Add New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Industry</Form.Label>
                  <Form.Control
                    type="text"
                    name="industry"
                    value={formData.industry}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="Enter numbers only"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Rating</Form.Label>
                  <Form.Control
                    type="number"
                    name="rating"
                    value={formData.rating}
                    onChange={handleRatingChange}
                    min="0"
                    max="5"
                    step="0.1"
                    required
                  />
                  <Form.Text className="text-muted">
                    Enter a value between 0 and 5
                  </Form.Text>
                </Form.Group>
              </Col>

              {/* Right Column */}
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Secondary Phone (Optional)</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone2"
                    value={formData.phone2}
                    onChange={handleChange}
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="Enter numbers only"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Website (Optional)</Form.Label>
                  <Form.Control
                    type="url"
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                    placeholder="https://example.com"
                    pattern="https?://.*"
                    title="Please include http:// or https://"
                  />
                  <Form.Text className="text-muted">
                    Please include http:// or https:// in the URL
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    type="text"
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                    type="text"
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Tags (Comma separated)</Form.Label>
                  <Form.Control
                    type="text"
                    name="tags"
                    value={formData.tags.join(', ')}
                    onChange={handleTagsChange}
                    placeholder="e.g., VIP, Enterprise, New Lead"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Description (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="text-end mt-3">
              <Button variant="secondary" onClick={close} className="me-2">
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />
                    Adding Customer...
                  </>
                ) : (
                  'Add Customer'
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`bg-${toastType} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </>
  );
};

export default AddCustomer;
