import { api } from '../../../api';
import { Button, Form, Modal, Toast } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

const EditLocationModal = ({ show, hide, location, index }) => {
  console.log(location, index);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ criteriaMode: 'all', mode: 'all' });

  const companyId = useSelector((state) => state.userReducer.data.companyId);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  useEffect(() => {
    if (location) {
      setValue('city', location.city);
      setValue('state', location.state);
      setValue('country', location.country);
    }
  }, [location, setValue]);

  const handleEditLocation = async (data) => {
    try {
      await api.patch(`/admin/company/${companyId}/location/${index}`, {
        city: data.city,
        state: data.state,
        country: data.country,
      });

      setToastMessage('Location updated successfully');
      setToastType('success');
      setShowToast(true);
      window.location.reload();
      hide();
    } catch (error) {
      console.error('Error updating location:', error);
      setToastMessage('Error updating location');
      setToastType('danger');
      setShowToast(true);
    }
  };

  return (
    <>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`bg-${toastType} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Body>
          <Button bsPrefix="btn-close" type="button" onClick={hide}>
            <span aria-hidden="true">×</span>
          </Button>
          <h5 className="mb-5">Edit Location</h5>
          <Form onSubmit={handleSubmit(handleEditLocation)}>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City"
                {...register('city', { required: 'City is required' })}
              />
              {errors.city && (
                <p className="text-danger mt-1">{errors.city.message}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter State"
                {...register('state')}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Country"
                {...register('country', { required: 'Country is required' })}
              />
              {errors.country && (
                <p className="text-danger mt-1">{errors.country.message}</p>
              )}
            </Form.Group>

            <Modal.Footer className="align-items-center">
              <Button variant="secondary" onClick={hide}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditLocationModal;
