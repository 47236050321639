import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner, Toast } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { api } from '../../api';
import RegexUtils from '../../utils/RegexUtils';

const EditLead = ({
  show,
  close,
  reload,
  setReload,
  stages,
  leadData,
  teamMembers,
}) => {
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [assignToError, setAssignToError] = useState(false);

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    stageId: '',
    companyName: '',
    address: '',
    selectedProcessor: null,
    status: '',
  });

  useEffect(() => {
    if (leadData) {
      setFormData({
        fullName: leadData.fullName || '',
        email: leadData.email || '',
        phone: leadData.phone || '',
        stageId: leadData.stageId || '',
        companyName: leadData.companyName || '',
        address: leadData.address || '',
        selectedProcessor: leadData.user
          ? {
              value: leadData.user.id,
              label: leadData.user.fullName,
              id: leadData.user.id,
            }
          : null,
        status: leadData.status || 'active',
      });
    }
  }, [leadData]);

  useEffect(() => {
    const employeeOptions = teamMembers.map((member) => ({
      value: member.id,
      label: member.fullName,
      id: member.id,
    }));
    setEmployeesOptions(employeeOptions);
  }, [teamMembers]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleProcessorChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, selectedProcessor: selectedOption }));
    setAssignToError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.selectedProcessor?.id) {
      setAssignToError(true);
      return;
    }

    try {
      setLoading(true);
      await api.patch(`/leads/${leadData.id}`, {
        fullName: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        stageId: formData.stageId,
        companyName: formData.companyName,
        address: formData.address,
        processor: formData.selectedProcessor.id,
        status: formData.status,
      });

      setToastType('success');
      setToastMessage('Lead updated successfully');
      setShowToast(true);
      setReload(!reload);
      close();
    } catch (error) {
      console.error(error);
      setToastType('danger');
      setToastMessage(
        error?.response?.data?.message || 'Something went wrong try later'
      );
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`bg-${toastType} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      <Modal show={show} onHide={close} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div className="title title-xs title-wth-divider text-primary text-uppercase my-4">
              <span>Lead Info</span>
            </div>
            <Row className="gx-3">
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                    pattern={RegexUtils.NAME.source}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    pattern={RegexUtils.EMAIL.source}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="gx-3">
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    pattern={RegexUtils.PHONE.source}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Stage</Form.Label>
                  <Form.Select
                    name="stageId"
                    value={formData.stageId}
                    onChange={handleChange}
                    required
                  >
                    {stages.map((stage) => (
                      <option key={stage.id} value={stage.id}>
                        {stage.stageType}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <div className="title title-xs title-wth-divider text-primary text-uppercase my-4">
              <span>Company Info</span>
            </div>
            <Row className="gx-3">
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Assign To</Form.Label>
                  <Select
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: '#032390',
                        boxShadow: state.isFocused ? null : null,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        height: '80px',
                      }),
                    }}
                    value={formData.selectedProcessor}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="assignTo"
                    options={employeesOptions}
                    onChange={handleProcessorChange}
                  />
                  {assignToError && (
                    <p className="text-danger mt-1">Person Name is required</p>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    required
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <div className="text-end mt-4">
              <Button variant="secondary" onClick={close} className="me-2">
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />
                    Updating Lead...
                  </>
                ) : (
                  'Update Lead'
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditLead;
