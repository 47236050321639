import React, { useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Plus } from 'react-feather';
import CreateNewLead from './CreateNewLead';
const LeadTableHeader = ({ reload, setReload }) => {
  const [addNewLead, setAddNewLead] = useState(false);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="card-border mb-0 h-100">
            <Card.Header className="card-header-action">
              <h6>Leads</h6>
              <div className="card-action-wrap">
                <Button
                  variant="primary"
                  size="sm"
                  className="ms-3"
                  onClick={() => setAddNewLead(!addNewLead)}
                >
                  <span>
                    <span className="icon">
                      <span className="feather-icon">
                        <Plus />
                      </span>
                    </span>
                    <span className="btn-text">Add new</span>
                  </span>
                </Button>
              </div>
            </Card.Header>
            <Card.Body>{/* Lead Table component would go here */}</Card.Body>
          </Card>
        </Col>
      </Row>
      <CreateNewLead
        reload={reload}
        setReload={setReload}
        show={addNewLead}
        close={() => setAddNewLead(false)}
      />
    </Container>
  );
};

export default LeadTableHeader;
