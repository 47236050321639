import { Toast as BootstrapToast } from 'react-bootstrap';

const Toast = ({ show, message, type, onClose }) => {
  return (
    <BootstrapToast
      show={show}
      onClose={onClose}
      delay={3000}
      autohide
      className={`bg-${type} text-white`}
      style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
    >
      <BootstrapToast.Body>{message}</BootstrapToast.Body>
    </BootstrapToast>
  );
};

export default Toast;
