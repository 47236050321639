import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Pagination, Row } from "react-bootstrap";
import SimpleBar from "simplebar-react";
//Bootstrap Table Library
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//Table data
import { useHistory } from "react-router-dom";
import { getColumns } from "./TableData";
//Table search
const { SearchBar } = Search;

const LeadTableBody = ({
  reload,
  setReload,
  stages,
  selectedStage,
  onSelectStage,
  leads,
  toggleEditLead,
  toggleDeleteLead,
  selectedFilter,
  onSelectFilter,
}) => {
  const role = useSelector((state) => state.userReducer.data.roleName);

  const handleEditClick = useCallback(
    (lead) => {
      toggleEditLead(lead);
    },
    [toggleEditLead]
  );

  const handleDeleteClick = useCallback(
    (lead) => {
      toggleDeleteLead(lead);
    },
    [toggleDeleteLead]
  );

  const columns = useMemo(
    () => getColumns(handleEditClick, handleDeleteClick),
    [handleEditClick, handleDeleteClick]
  );

  const history = useHistory();
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  //Custom Total layout
  const customTotal = (from, to, size) => (
    <span className="ms-2">
      {from} - {to} of {size}
    </span>
  );

  //Custom Select Box
  const selectRow = {
    mode: "checkbox",
    selectionHeaderRenderer: ({ mode, indeterminate, ...rest }) => (
      <Form.Check
        type={mode}
        className="fs-6 mb-0"
        ref={(input) => {
          if (input) input.indeterminate = false;
        }}
        {...rest}
        readOnly
      />
    ),
    selectionRenderer: ({ mode, checked }) => (
      <div className="d-flex align-items-center">
        <Form.Check type={mode} checked={checked} readOnly />
      </div>
    ),
    style: { backgroundColor: "#ebf5f5", color: "black" },
  };

  //Options for Table Paginations
  const handleNextPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page + 1);
    };

  const handlePrevPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page - 1);
    };
  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    withFirstAndLast: false,
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    totalSize: leads?.length || 0,
  };

  return (
    <div className="contact-body">
      <SimpleBar className="nicescroll-bar">
        <div className="contact-list-view">
          <ToolkitProvider keyField="id" columns={columns} data={leads} search>
            {(props) => (
              <>
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <>
                      <Row className="mb-3">
                        <Col sm={7}>
                          <div className="contact-toolbar-left d-flex align-items-center">
                            <Form.Group className="d-flex align-items-center mb-0 ms-3">
                              <Form.Select
                                onChange={(e) => onSelectStage(e.target.value)}
                                size="sm"
                                className="w-130p"
                                value={selectedStage}
                              >
                                {stages?.map((stage) => (
                                  <option key={stage.id} value={stage.id}>
                                    {stage.stageType}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            {role !== "ADMIN" && (
                              <Form.Group className="d-flex align-items-center mb-0 ms-3">
                                <Form.Select
                                  value={selectedFilter}
                                  onChange={(e) =>
                                    onSelectFilter(e.target.value)
                                  }
                                  size="sm"
                                  className="w-130p"
                                />
                              </Form.Group>
                            )}
                          </div>
                        </Col>
                        <Col sm={5}>
                          <div className="contact-toolbar-right d-flex justify-content-end">
                            <div className="dataTables_filter">
                              <Form.Label className="mb-0">
                                <Form.Control
                                  as={SearchBar}
                                  {...props.searchProps}
                                  size="sm"
                                  type="search"
                                  placeholder="Search"
                                />
                              </Form.Label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <BootstrapTable
                        keyField="id"
                        data={leads}
                        columns={columns}
                        selectRow={selectRow}
                        bordered={false}
                        wrapperClasses="table-responsive"
                        classes="nowrap w-100 mb-5"
                        {...paginationTableProps}
                        defaultSorted={defaultSorted}
                        {...props.baseProps}
                      />

                      <Row>
                        <Col>
                          <PaginationTotalStandalone {...paginationProps} />
                        </Col>
                        <Col>
                          <Pagination className="custom-pagination pagination-simple d-flex justify-content-end">
                            <Pagination.Prev
                              onClick={handlePrevPage(paginationProps)}
                              disabled={paginationProps.page === 1}
                            >
                              {" "}
                              <i className="ri-arrow-left-s-line" />{" "}
                            </Pagination.Prev>
                            <Pagination.Item active>
                              {paginationProps.page}
                            </Pagination.Item>
                            <Pagination.Next
                              onClick={handleNextPage(paginationProps)}
                              disabled={
                                paginationProps.page >=
                                leads.length / paginationProps.sizePerPage
                              }
                            >
                              {" "}
                              <i className="ri-arrow-right-s-line" />{" "}
                            </Pagination.Next>
                          </Pagination>
                        </Col>
                      </Row>
                    </>
                  )}
                </PaginationProvider>
              </>
            )}
          </ToolkitProvider>
        </div>
      </SimpleBar>
    </div>
  );
};

export default LeadTableBody;
