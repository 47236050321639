import classNames from "classnames";
import { Button } from "react-bootstrap";
import { Edit, Trash } from "react-feather";
import { Link } from "react-router-dom";
import { ArrowsSort, SortAscending, SortDescending } from "tabler-icons-react";
import HkBadge from "../../../components/@hk-badge/@hk-badge";

//Images
import AvatarLetters from "../../../utils/AvatarLetters";

//Custom Sorting Caret
const customCaret = (order, column) => {
  if (!order)
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp; <ArrowsSort size={14} strokeWidth={2.5} />{" "}
      </span>
    );
  else if (order === "asc")
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <font color="#032390">
          <SortAscending size={14} strokeWidth={2.5} />
        </font>
      </span>
    );
  else if (order === "desc")
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <font color="#032390">
          <SortDescending size={14} strokeWidth={2.5} />
        </font>
      </span>
    );
  return null;
};
//Custom Sorting Classes
const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) =>
  sortOrder === "asc" ? "text-primary" : "text-primary";

//Custom Avatar Container
const nameFormater = (cell, row) => {
  // alert(JSON.stringify(cell))

  const data = { cstmAvt: "C", avtBg: "info", fullName: cell };
  let avt = AvatarLetters(data.fullName);
  return (
    <div className="media align-items-center">
      <div className="media-head me-2">
        <div
          className={classNames(
            "avatar avatar-xs avatar-rounded",
            `avatar-soft-info`
          )}
        >
          {/* {data.Img && <img src={data.Img} alt="user" className="avatar-img" />} */}
          <span className="initial-wrap">{avt}</span>
        </div>
      </div>
      <div className="media-body">
        <span className="d-block text-high-em">{data.fullName}</span>
      </div>
    </div>
  );
};

//Custom Tag Container
const tagFormater = (cell) => {
  return (
    <HkBadge bg={"primary"} soft className="my-1 me-2">
      {cell.stageType}
    </HkBadge>
  );
};

const dateFormater = (cell) => {
  return <span>{new Date(cell).toLocaleDateString()}</span>;
};

const statusFormater = (cell) => {
  return (
    <HkBadge
      bg={cell === "active" ? "success" : "danger"}
      soft
      className="my-1 me-2"
    >
      {cell}
    </HkBadge>
  );
};

//Custom Action Container
const actionFormater = (cell) => {
  return cell.map((data, indx) => (
    <div className="d-flex align-items-center" key={indx}>
      <div className="d-flex">
        <Button
          variant="flush-dark"
          as={Link}
          to={data.editLink}
          className="btn-icon btn-rounded flush-soft-hover"
          data-bs-toggle="tooltip"
          data-placement="top"
          data-bs-original-title="Edit"
        >
          <span className="icon">
            <span className="feather-icon">
              <Edit />
            </span>
          </span>
        </Button>
        <Button
          variant="flush-dark"
          as={Link}
          to={data.deleteLink}
          className="btn-icon btn-rounded flush-soft-hover del-button"
          data-bs-toggle="tooltip"
          data-placement="top"
          data-bs-original-title="Delete"
        >
          <span className="icon">
            <span className="feather-icon">
              <Trash />
            </span>
          </span>
        </Button>
      </div>
    </div>
  ));
};

export const getColumns = (handleEditClick, handleDeleteClick) => [
  {
    dataField: "id",
    text: "Lead ID",
    hidden: true,
  },
  {
    dataField: "fullName",
    text: "Lead Name",
    sort: true,
    formatter: nameFormater,
    sortCaret: customCaret,
    headerSortingClasses,
  },
  {
    dataField: "companyName",
    text: "Company Name",
    sort: true,
    formatter: (cell) => (
      <span className="text-truncate mw-150p d-block">{cell}</span>
    ),
    sortCaret: customCaret,
    headerSortingClasses,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    formatter: (cell) => (
      <span className="text-truncate mw-150p d-block">{cell}</span>
    ),
    sortCaret: customCaret,
    headerSortingClasses,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
    sortCaret: customCaret,
    headerSortingClasses,
  },
  {
    dataField: "companyLeadStage",
    text: "Lead Stage",
    sort: true,
    formatter: tagFormater,
    sortCaret: customCaret,
    headerSortingClasses,
  },
  {
    dataField: "user.fullName",
    text: "LeadOwner",
    sort: true,
    sortCaret: customCaret,
    headerSortingClasses,
  },
  {
    dataField: "createdAt",
    text: "Created At",
    sort: true,
    formatter: dateFormater,
    sortCaret: customCaret,
    headerSortingClasses,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    sortCaret: customCaret,
    formatter: statusFormater,
    headerSortingClasses,
  },
  {
    dataField: "actions",
    text: "Actions",
    formatter: (cell, row) => (
      <div className="d-flex">
        <Button
          variant="flush-dark"
          className="btn-icon btn-rounded flush-soft-hover"
          onClick={() => handleEditClick(row)}
        >
          <span className="icon">
            <span className="feather-icon">
              <Edit />
            </span>
          </span>
        </Button>
        {/* <Button
          variant="flush-dark"
          className="btn-icon btn-rounded flush-soft-hover del-button"
          onClick={() => handleDeleteClick(row)}
        >
          <span className="icon">
            <span className="feather-icon">
              <Trash />
            </span>
          </span>
        </Button> */}
      </div>
    ),
  },
];
// const StageDropDown = () => {

//     return (
//         <>
//             <Dropdown>
//                 <Dropdown.Toggle as="a" className="text" href="#" >
//                     <h1 style={{ fontSize: '15px' }} >Stage</h1>
//                 </Dropdown.Toggle>
//                 <Dropdown.Menu>
//                     <Dropdown.Item>
//                         <span className="feather-icon dropdown-icon">
//                         </span>
//                         <span>Begin</span>
//                     </Dropdown.Item>
//                     <Dropdown.Item>
//                         <span className="feather-icon dropdown-icon">
//                         </span>
//                         <span>Call</span>
//                     </Dropdown.Item>
//                     <Dropdown.Item>
//                         <span className="feather-icon dropdown-icon">
//                             <Archive />
//                         </span>
//                         <span>Pending</span>
//                     </Dropdown.Item>
//                     <Dropdown.Item>
//                         <span className="feather-icon dropdown-icon">
//                             <Edit />
//                         </span>
//                         <span>Completed</span>
//                     </Dropdown.Item>
//                 </Dropdown.Menu>
//             </Dropdown>
//         </>
//     )
// }
