import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import React, { Suspense, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import PageAnimate from "../components/Animation/PageAnimate";
import { authRoutes } from "./RouteList";

const AuthRoutes = (props) => {
  const { match } = props;
  const lockScreenAuth = useRouteMatch("/auth/lock-screen");
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) history.replace("/dashboard");
  }, []);

  return (
    <>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="preloader-it">
              <div className="loader-pendulums" />
            </div>
          }
        >
          <div
            className={classNames("hk-wrapper hk-pg-auth", {
              "bg-primary-dark-3": lockScreenAuth,
            })}
            data-footer="simple"
          >
            <Switch>
              {authRoutes.map((obj, i) => {
                return obj.component ? (
                  <Route
                    key={i}
                    exact={obj.exact}
                    path={match.path + obj.path}
                    render={(matchProps) => (
                      <>
                        <PageAnimate>
                          <obj.component {...matchProps} />
                        </PageAnimate>
                      </>
                    )}
                  />
                ) : null;
              })}
              {/* <Redirect exact from='/' to='/dashboard' /> */}
              <Route path="*">
                <Redirect to="/error/error-404" />
              </Route>
            </Switch>
          </div>
        </Suspense>
      </AnimatePresence>
    </>
  );
};

export default AuthRoutes;
