import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import ReactApexChart from 'react-apexcharts';
import { api } from '../../api';

const LeadStats = () => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    async function fetchStats() {
      const response = await api.get('/leads/stats');
      setStats(response.data.data.byStage);
    }
    fetchStats();
  }, []);

  const options = {
    chart: {
      type: 'bar',
      height: 250,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: '#646A71',
      fontFamily: 'DM Sans',
    },
    grid: {
      borderColor: '#F4F5F6',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '35%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    xaxis: {
      categories: stats.map((stage) => stage.companyLeadStage.stageType),
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'inherit',
        },
      },
      axisBorder: {
        show: false,
      },
      title: {
        style: {
          fontSize: '12px',
          fontFamily: 'inherit',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'inherit',
        },
        formatter: function (value) {
          return Math.round(value); // Ensure integer display
        },
      },
      title: {
        style: {
          fontSize: '12px',
          fontFamily: 'inherit',
        },
      },
    },
    legend: {
      show: true,
      position: 'top',
      fontSize: '15px',
      labels: {
        colors: '#6f6f6f',
      },
      markers: {
        width: 8,
        height: 8,
        radius: 15,
      },
      itemMargin: {
        vertical: 5,
      },
    },
    colors: ['#032390', '#029BDE', '#ebf3fe'],
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
  };

  const series = [
    {
      name: 'Leads Count',
      data: stats.map((stage) => stage.count),
    },
  ];

  return (
    <Card
      className="mb-3 mx-auto"
      style={{ borderRadius: '10px', width: '90%' }}
    >
      <Card.Header>
        <Container>
          <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Lead Stages Overview</h5>
            </Col>
          </Row>
        </Container>
      </Card.Header>
      <Card.Body>
        {stats.length > 0 ? (
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={270}
          />
        ) : (
          <p className="text-center">No data available.</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default LeadStats;
