import {
  Archive,
  Copy,
  Edit,
  MoreVertical,
  Trash,
  Trash2,
} from 'react-feather';
import { ArrowsSort, SortAscending, SortDescending } from 'tabler-icons-react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import HkBadge from '../../../components/@hk-badge/@hk-badge';

//Images
import avatar1 from '../../../assets/dist/img/avatar1.jpg';
import avatar2 from '../../../assets/dist/img/avatar2.jpg';
import avatar3 from '../../../assets/dist/img/avatar3.jpg';
import avatar7 from '../../../assets/dist/img/avatar7.jpg';
import avatar8 from '../../../assets/dist/img/avatar8.jpg';
import avatar9 from '../../../assets/dist/img/avatar9.jpg';
import avatar10 from '../../../assets/dist/img/avatar10.jpg';
import avatar13 from '../../../assets/dist/img/avatar13.jpg';
import AvatarLetters from '../../../utils/AvatarLetters';
import { useSelector } from 'react-redux';

//Custom Sorting Caret
const customCaret = (order, column) => {
  if (!order)
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp; <ArrowsSort size={14} strokeWidth={2.5} />{' '}
      </span>
    );
  else if (order === 'asc')
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <font color="#032390">
          <SortAscending size={14} strokeWidth={2.5} />
        </font>
      </span>
    );
  else if (order === 'desc')
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <font color="#032390">
          <SortDescending size={14} strokeWidth={2.5} />
        </font>
      </span>
    );
  return null;
};
//Custom Sorting Classes
const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) =>
  sortOrder === 'asc' ? 'text-primary' : 'text-primary';

//Custom Avatar Container
const nameFormater = (roleType) => (cell, row) => {
  // const data = { "cstmAvt": "C", "avtBg": "info", "fullName": cell }
  let avt = AvatarLetters(cell);
  console.log('tabledata', roleType);
  return (
    <div className="media align-items-center">
      <div className="media-head me-2">
        <div
          className={classNames(
            'avatar avatar-xs avatar-rounded',
            `avatar-soft-info`
          )}
        >
          <span className="initial-wrap">{avt}</span>
        </div>
      </div>
      <div className="media-body">
        {roleType == 1 ? (
          <Link
            to={{
              pathname: '/app/employees/edit-employee',
              state: { data: row },
            }}
          >
            <span className="d-block text-high-em">{cell}</span>
          </Link>
        ) : (
          <span className="d-block text-high-em">{cell}</span>
        )}
      </div>
    </div>
  );
};

//Custom Tag Container
const roleFormatter = (cell) => {
  return (
    <>
      {/* <HkBadge bg={data.bg} soft className="my-1  me-2" >{data.title}</HkBadge> */}
      <p>{cell == 1 ? 'Admin' : 'Operator'}</p>
    </>
  );
};

export const columns = (roleType) => [
  {
    dataField: 'id',
    text: 'Employee ID',
    hidden: true,
  },
  {
    dataField: 'fullName',
    text: 'Name',
    sort: true,
    formatter: nameFormater(roleType),
    sortCaret: customCaret,
    headerSortingClasses,
  },
  {
    dataField: 'email',
    text: 'Email Address',
    sort: true,
    formatter: (cell) => (
      <span className="text-truncate mw-150p d-block">{cell}</span>
    ),
    sortCaret: customCaret,
    headerSortingClasses,
  },
  {
    dataField: 'phone',
    text: 'Phone',
    sort: true,
    sortCaret: customCaret,
    headerSortingClasses,
  },
  {
    dataField: 'roleType',
    text: 'Role',
    sort: true,
    formatter: roleFormatter,
    sortCaret: customCaret,
    headerSortingClasses,
  },
];
