import {
  Archive,
  Copy,
  Edit,
  MoreVertical,
  Trash,
  Trash2,
} from 'react-feather';
import { ArrowsSort, SortAscending, SortDescending } from 'tabler-icons-react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

//Images
import AvatarLetters from '../../utils/AvatarLetters';

//Custom Sorting Caret
const customCaret = (order, column) => {
  if (!order)
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp; <ArrowsSort size={14} strokeWidth={2.5} />{' '}
      </span>
    );
  else if (order === 'asc')
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <font color="#032390">
          <SortAscending size={14} strokeWidth={2.5} />
        </font>
      </span>
    );
  else if (order === 'desc')
    return (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <font color="#032390">
          <SortDescending size={14} strokeWidth={2.5} />
        </font>
      </span>
    );
  return null;
};
//Custom Sorting Classes
const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) =>
  sortOrder === 'asc' ? 'text-primary' : 'text-primary';

//Custom Avatar Container
const nameFormater = (cell, row) => {
  console.log(cell);
  // alert(JSON.stringify(cell))

  const data = { cstmAvt: 'C', avtBg: 'info', fullName: cell };
  let avt = AvatarLetters(data.fullName);
  return (
    <div className="media align-items-center">
      <div className="media-head me-2">
        <div
          className={classNames(
            'avatar avatar-xs avatar-rounded',
            `avatar-soft-info`
          )}
        >
          {/* {data.Img && <img src={data.Img} alt="user" className="avatar-img" />} */}
          <span className="initial-wrap">{avt}</span>
        </div>
      </div>
      <div className="media-body">
        <Link to={{ pathname: '/app/leads/edit-lead', state: { data: row } }}>
          <span className="d-block text-high-em">{data.fullName}</span>
        </Link>
      </div>
    </div>
  );
};

//Custom Action Container
const actionFormater = (cell) => {
  return cell.map((data, indx) => (
    <div className="d-flex align-items-center" key={indx}>
      <div className="d-flex">
        <Button
          variant="flush-dark"
          as={Link}
          to={data.editLink}
          className="btn-icon btn-rounded flush-soft-hover"
          data-bs-toggle="tooltip"
          data-placement="top"
          data-bs-original-title="Edit"
        >
          <span className="icon">
            <span className="feather-icon">
              <Edit />
            </span>
          </span>
        </Button>
        <Button
          variant="flush-dark"
          as={Link}
          to={data.deleteLink}
          className="btn-icon btn-rounded flush-soft-hover del-button"
          data-bs-toggle="tooltip"
          data-placement="top"
          data-bs-original-title="Delete"
        >
          <span className="icon">
            <span className="feather-icon">
              <Trash />
            </span>
          </span>
        </Button>
      </div>
    </div>
  ));
};

const designationFormatter = (cell) => (
  <div className="d-flex align-items-center">
    <span className="badge bg-primary">{cell}</span>
  </div>
);

export const getColumns = (onEditClick, onDeleteClick) => [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    sortCaret: customCaret,
  },
  {
    dataField: 'fullName',
    text: 'Full Name',
    sort: true,
    sortCaret: customCaret,
    formatter: (cell, row) => {
      let avt = AvatarLetters(cell);
      return (
        <div className="media align-items-center">
          <div className="media-head me-2">
            <div
              className={classNames(
                'avatar avatar-xs avatar-rounded',
                'avatar-soft-info'
              )}
            >
              <span className="initial-wrap">{avt}</span>
            </div>
          </div>
          <div className="media-body">
            <span className="d-block text-high-em">{cell}</span>
          </div>
        </div>
      );
    },
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
    sortCaret: customCaret,
  },
  {
    dataField: 'phone',
    text: 'Phone',
    sort: true,
    sortCaret: customCaret,
  },
  {
    dataField: 'designation.name',
    text: 'Designation',
    sort: true,
    formatter: designationFormatter,
    sortCaret: customCaret,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    formatter: (cellContent, row) => (
      <Dropdown>
        <Dropdown.Toggle variant="outline-secondary" size="sm">
          <MoreVertical size={14} />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item href="#" onClick={() => onEditClick(row)}>
            <Edit size={14} className="me-2" /> Edit
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            className="link-danger"
            onClick={() => onDeleteClick(row)}
          >
            <Trash2 size={14} className="me-2" /> Remove
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];
