import {
  GET_USER_ACCOUNT_FULFILLED,
  GET_USER_ACCOUNT_PENDING,
  GET_USER_ACCOUNT_REJECTED,
} from '../constants/UserDetails';

const initialState = {
  data: {
    id: 0,
    companyId: 0,
    fullName: '',
    email: '',
    phone: '',
    roleType: 0,
  },
};

export function UserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_ACCOUNT_FULFILLED:
      return { ...state, data: action.payload, pending: false };
    case GET_USER_ACCOUNT_REJECTED:
      return { ...state, error: action.error, pending: false };
    case GET_USER_ACCOUNT_PENDING:
      return { ...state, pending: true };
    default:
      return state;
  }
}
