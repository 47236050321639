import React from "react";
import { errorRoutes } from "./RouteList";
import { Redirect, Route, Switch } from "react-router-dom";

const ErrorRoutes = (props) => {
  const { match } = props;

  return (
    <Switch>
      {errorRoutes.map((obj, i) => {
        return obj.component ? (
          <Route
            key={i}
            exact={obj.exact}
            path={match.path + obj.path}
            render={(matchProps) => (
              <>
                <obj.component {...matchProps} />
              </>
            )}
          />
        ) : null;
      })}
    </Switch>
  );
};

export default ErrorRoutes;
