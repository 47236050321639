import { useMemo, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Pagination,
  Row,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Plus } from 'react-feather';

import { getColumns } from './data';
const { SearchBar } = Search;

const CustomersTable = ({
  customers,
  sortActiveCustomers,
  sortInactiveCustomers,
  showAllCustomers,
  toggleEditCustomer,
  toggleDeleteCustomer,
}) => {
  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="ms-2">
      {from} - {to} of {size}
    </span>
  );

  const handleEditClick = useCallback(
    (customer) => {
      toggleEditCustomer(customer);
    },
    [toggleEditCustomer]
  );

  const handleDeleteClick = useCallback(
    (customer) => {
      toggleDeleteCustomer(customer);
    },
    [toggleDeleteCustomer]
  );

  const columns = useMemo(
    () => getColumns(handleEditClick, handleDeleteClick),
    [handleEditClick, handleDeleteClick]
  );

  const selectRow = {
    mode: 'checkbox',
    selectionHeaderRenderer: ({ mode, indeterminate, ...rest }) => (
      <Form.Check
        type={mode}
        className="fs-6 mb-0"
        ref={(input) => {
          if (input) input.indeterminate = false;
        }}
        {...rest}
        readOnly
      />
    ),
    selectionRenderer: ({ mode, checked }) => (
      <Form.Check type={mode} checked={checked} readOnly />
    ),
    style: { backgroundColor: '#ebf5f5', color: 'black' },
  };

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    withFirstAndLast: false,
    sizePerPage: 4,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    totalSize: customers.length,
  };

  const CustomToggleList = () => (
    <div className="d-xxl-flex d-none align-items-center">
      <ButtonGroup size="sm">
        <Button variant="outline-light" onClick={showAllCustomers}>
          View all
        </Button>
        <Button variant="outline-light" onClick={sortActiveCustomers}>
          Active
        </Button>
        <Button variant="outline-light" onClick={sortInactiveCustomers}>
          Inactive
        </Button>
      </ButtonGroup>
    </div>
  );

  return (
    <div className="contact-list-view">
      <ToolkitProvider keyField="id" columns={columns} data={customers} search>
        {(props) => (
          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => (
              <>
                <Row className="mb-3">
                  <Col sm={8}>
                    <CustomToggleList />
                  </Col>
                  <Col sm={4} className="text-right d-flex justify-content-end">
                    <SearchBar
                      {...props.searchProps}
                      className="form-control form-control-sm"
                      type="search"
                    />
                  </Col>
                </Row>
                <BootstrapTable
                  keyField="id"
                  data={customers}
                  columns={columns}
                  selectRow={selectRow}
                  bordered={false}
                  classes="dataTable display pb-30"
                  wrapperClasses="table-responsive scrollable-able"
                  {...paginationTableProps}
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                />
                <Row>
                  <Col>
                    <PaginationTotalStandalone {...paginationProps} />
                  </Col>
                  <Col>
                    <Pagination className="custom-pagination pagination-simple pagination-sm d-flex justify-content-end">
                      <Pagination.Prev
                        onClick={() =>
                          paginationProps.onPageChange(paginationProps.page - 1)
                        }
                        disabled={paginationProps.page === 1}
                      >
                        <i className="ri-arrow-left-s-line" />
                      </Pagination.Prev>
                      <Pagination.Item active>
                        {paginationProps.page}
                      </Pagination.Item>
                      <Pagination.Next
                        onClick={() =>
                          paginationProps.onPageChange(paginationProps.page + 1)
                        }
                        disabled={
                          paginationProps.page >=
                          paginationProps.dataSize /
                            paginationProps.paginationSize
                        }
                      >
                        <i className="ri-arrow-right-s-line" />
                      </Pagination.Next>
                    </Pagination>
                  </Col>
                </Row>
              </>
            )}
          </PaginationProvider>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default CustomersTable;
