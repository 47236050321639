import { api } from '../../api';
import { Button, Modal, Toast } from 'react-bootstrap';
import { useState } from 'react';

const DeleteCustomer = ({ show, close, customerData, onDelete }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  const deleteCustomer = async () => {
    try {
      await api.delete(`/customers/${customerData.id}`);
      setToastMessage('Customer deleted successfully');
      setToastType('success');
      setShowToast(true);
      close();
      onDelete(customerData.id);
    } catch (error) {
      setToastMessage(error.response?.data?.message || 'An error occurred');
      setToastType('danger');
      setShowToast(true);
    }
  };

  return (
    <>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`bg-${toastType} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      <Modal show={show} onHide={close} size="lg" centered>
        <Modal.Body>
          <Button bsPrefix="btn-close" type="button" onClick={close}>
            <span aria-hidden="true">×</span>
          </Button>
          <h5 className="mb-5">Delete Customer</h5>
          <p>
            Are you sure you want to delete
            {`${customerData?.firstName} ${customerData?.lastName}`}?
          </p>
          <Modal.Footer className="align-items-center">
            <Button variant="secondary" onClick={close}>
              Close
            </Button>
            <Button variant="danger" onClick={deleteCustomer}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteCustomer;
